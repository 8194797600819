import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

const AnimationComponent = ({ animate, children, once = false }) => {
    const [animation, setAnimation] = useState(false);
    const [disable, setDisable] = useState(false);
    const [style, setStyle] = useState({});

    useEffect(() => {
        if (animate === 'fadeIn') {
            setStyle({
                opacity: 0,
                height: '100%'
            });
        }
    }, [animate]);

    const addAnimation = useCallback(() => {
        setAnimation(true);
        setDisable(once);
    }, [once]);

    const removeAnimation = useCallback(() => {
        setAnimation(false);
    }, []);

    const toggleAnimation = () => {
        return animation ? animate : '';
    };

    return (
        <Waypoint
            onEnter={!disable ? addAnimation : undefined}
            onLeave={!once ? removeAnimation : undefined}
        >
            <div className={`animated ${toggleAnimation()}`} style={style}>
                {children}
            </div>
        </Waypoint>
    );
};

AnimationComponent.propTypes = {
    animate: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    once: PropTypes.bool
};

export default AnimationComponent;
