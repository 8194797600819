import '../styles/ServiceOfferings.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Card from '../components/Card';

class ProductOfferingsComponent extends React.PureComponent {
	render() {
		const { productList } = this.props;
		return (
			<div className="card-list container">
				{productList.map((product, i) => {
					let className;
					if (i % 3 === 2) {
						className = 'col-md-12';
					} else {
						className = 'col-md-6';
					}
					return (
						<Card key={product.id} className={className} list={product} parent="/products" />
					);
				})}
			</div>
		);
	}
}

ProductOfferingsComponent.propTypes = {
	productList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ProductOfferingsComponent;
