import '../styles/Team.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TeamProfile from '../components/TeamProfile';

class TeamComponent extends React.PureComponent {
	render() {
		const { techTeam, advisoryTeam, businessTeam } = this.props;
		// handle parameters that are not in effective use into voids to avoid errors
		let voidTeam1 = techTeam;
		let voidTeam2 = advisoryTeam;
		voidTeam1 = voidTeam2;
		voidTeam2 = voidTeam1;

		return (
			<div className="col-12 col-lg-9 mx-auto">
				<div className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="team.title"
						/>
					</h4>
				</div>

				{/* Tech Team Section */}
				{/*
				<div className="team">
					<div className="col-md-11 mx-auto">
						<h5 className="text-bigger text-bold">
							<FormattedMessage
								id="team.subtitle.tech"
							/>
						</h5>
					</div>
					{techTeam.map(({
						id, name, position, description, image
					}) => (
						<TeamProfile
							key={id}
							name={name}
							position={position}
							description={description}
							image={image}
						/>
					))}
				</div>
				*/}

				{/* Advisory Team Section */}
				{/*
				<div className="team">
					<div className="col-md-11 mx-auto">
						<h5 className="text-bigger text-bold">
							<FormattedMessage
								id="team.subtitle.advisory"
							/>
						</h5>
					</div>
					{advisoryTeam.map(({
						id, name, position, description, image
					}) => (
						<TeamProfile
							key={id}
							name={name}
							position={position}
							description={description}
							image={image}
						/>
					))}
				</div>
				*/}

				{/* Business Team Section cum Founders and Management */}
				<div className="team">
					<div className="col-md-11 mx-auto">
						<h5 className="text-bigger text-bold">
							<FormattedMessage
								id="team.subtitle.business"
							/>
						</h5>
					</div>
					{businessTeam.map(({
						id, name, position, description, image, email
					}) => (
						<TeamProfile
							key={id}
							name={name}
							position={position}
							description={description}
							image={image}
							email={email}
						/>
					))}
				</div>
			</div>
		);
	}
}

TeamComponent.propTypes = {
	techTeam: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string
	})).isRequired,
	advisoryTeam: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string
	})).isRequired,
	businessTeam: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string
	})).isRequired
};

export default TeamComponent;
