import { FETCH_NAVS } from '../actions/navs';

const INITIAL_STATE = { navList: [] };

const navReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_NAVS:
			return { ...state, navList: action.payload };
		default:
			return state;
	}
};

export default navReducer;
