import { FETCH_TESTIMONIALS } from '../actions/testimonials';

const INITIAL_STATE = { testimonialList: [] };

const testimonialsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_TESTIMONIALS:
			return { ...state, testimonialList: action.payload };
		default:
			return state;
	}
};

export default testimonialsReducer;
