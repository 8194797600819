import '../styles/Clients.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Banner from '../components/Banner';
// import Animation from '../components/Animation';

import client1 from '../assets/images/client-oucci.png';
import client2 from '../assets/images/client-persafe.png';
import client3 from '../assets/images/partner-sago.png';
import client4 from '../assets/images/partner-tegas.png';

const clientList = [
	client1,
	client2,
	client3,
	client4
];

function ClientsComponent() {
	const clientListDom = clientList.map(client => (
		<div key={client} className="col-6 col-md-3">
			<img src={client} alt="" />
		</div>
	));

	return (
		<Banner id="clients" className="clients">
			<div className="col-md-8 mx-auto black">
				<div className="clients-desc">
					<h4 className="page-title">
						<FormattedMessage
							id="clients.title"
						/>
					</h4>
					<p>
						<FormattedMessage
							id="clients.description"
						/>
					</p>
				</div>
				{/* <Animation animate="fadeIn" once> */}
					<div className="row client-logo">
						{clientListDom}
					</div>
				{/* </Animation> */}
			</div>
		</Banner>
	);
}

ClientsComponent.propTypes = {
};

export default ClientsComponent;
