import { FETCH_TEAM } from '../actions/team';

const INITIAL_STATE = { techTeam: [], advisoryTeam: [], businessTeam: [] };

const teamReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_TEAM:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default teamReducer;
