import jobList from '../sources/jobs';

export const FETCH_JOBS = 'FETCH_JOBS';

export function fetchJobs() {
	return {
		type: FETCH_JOBS,
		payload: jobList
	};
}
