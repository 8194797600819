import '../styles/ServiceOfferings.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Card from '../components/Card';

class ServiceOfferingsComponent extends React.PureComponent {
	render() {
		const { serviceList } = this.props;
		return (
			<div className="card-list container">
				{serviceList.map((service, i) => {
					let className;
					if (i % 3 === 2) {
						className = 'col-md-12';
					} else {
						className = 'col-md-6';
					}
					return (
						<Card key={service.id} className={className} list={service} parent="/services" />
					);
				})}
			</div>
		);
	}
}

ServiceOfferingsComponent.propTypes = {
	serviceList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ServiceOfferingsComponent;
