import '../styles/Carousel.scss';
import '../styles/ServiceCarousel.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import Banner from '../components/Banner';
import ServiceCarouselPhase from '../components/ServiceCarouselPhase';

const initialSlide = 0;

class ServiceCarouselComponent extends React.Component {
	constructor(props) {
		super(props);

		this.nextSlide = this.nextSlide.bind(this);
		this.goToSlide = this.goToSlide.bind(this);
	}

	nextSlide(from, to) {
		this.phases.setState({
			from, to
		});
	}

	goToSlide(i) {
		this.slider.slickGoTo(i);
	}

	render() {
		const settings = {
			dots: false,
			speed: 0,
			initialSlide,
			beforeChange: this.nextSlide
		};
		const phaseTitle = [];
		const htmlContent = [];
		const { slides } = this.props;

		slides.map((slide, id) => {
			const split = slide.split(':');
			phaseTitle.push(split[0]);
			return htmlContent.push({
				id,
				html: split[1]
			});
		});
		return (
			<Banner className="slides">
				<ServiceCarouselPhase
					ref={(ref) => { this.phases = ref; }}
					initialSlide={initialSlide}
					titleArr={phaseTitle}
					goToSlide={this.goToSlide}
				/>
				<Slider ref={(ref) => { this.slider = ref; }} className="carousel mx-auto" {...settings}>
					{/* eslint-disable-next-line react/no-danger */}
					{htmlContent.map(content => <div key={content.id} className="carousel-content" dangerouslySetInnerHTML={{ __html: content.html }} />)}
				</Slider>
			</Banner>
		);
	}
}

ServiceCarouselComponent.propTypes = {
	slides: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ServiceCarouselComponent;
