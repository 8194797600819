import slide1 from '../assets/images/approach-discovery.png';
import slide2 from '../assets/images/approach-design-thinking.png';
import slide3 from '../assets/images/approach-scrum.png';
import slide4 from '../assets/images/approach-coach.png';

const approachList = [{
	id: 1,
	title: {
		text: 'approach.slide1text',
		highlight: {
			highlight1: 'approach.slide1Highlight1',
			highlight2: 'approach.slide1Highlight2'
		}
	},
	image: slide1
}, {
	id: 2,
	title: {
		text: 'approach.slide2text',
		highlight: {
			highlight1: 'approach.slide2Highlight1'
		}
	},
	image: slide2
}, {
	id: 3,
	title: {
		text: 'approach.slide3text',
		highlight: {
			highlight1: 'approach.slide3Highlight1',
			highlight2: 'approach.slide3Highlight2'
		}
	},
	image: slide3
}, {
	id: 4,
	title: {
		text: 'approach.slide4text',
		highlight: {
			highlight1: 'approach.slide4Highlight1',
			highlight2: 'approach.slide4Highlight2'
		}
	},
	image: slide4
}];

export default approachList;
