import '../styles/Banner.scss';

import React from 'react';
import PropTypes from 'prop-types';

class BannerComponent extends React.PureComponent {
	render() {
		const {
			imgUrl,
			className,
			style,
			id,
			children
		} = this.props;

		const background = {
			backgroundImage: imgUrl ? (`url(${imgUrl})`) : 'none'
		};
		return (
			<div className={`banner${className ? ` ${className}` : ''}`} style={{ ...background, ...style }} id={id}>
				<div className="banner-text">{children}</div>
			</div>
		);
	}
}

BannerComponent.propTypes = {
	className: PropTypes.string,
	style: PropTypes.string,
	id: PropTypes.string,
	imgUrl: PropTypes.string,
	children: PropTypes.node.isRequired
};

BannerComponent.defaultProps = {
	className: null,
	style: '',
	id: '',
	imgUrl: null
};

export default BannerComponent;
