import _ from 'lodash';

import snowflakeCoaching from './snowflakeCoaching';
//import HarnessingGenerativeAI from './harnessingGenerativeAI';
import { harnessingGenerativeAIArticle } from './harnessingGenerativeAI';
import Snowflake from './snowflake';
// import DigitalOutreachPlatform from './digitalOutreachPlatform';
// import PartnerWithShareShare from './partnerWithShareShare';
import LightBulb from './lightBulb';
// import GrabCase from '@/sources/articles/grabCase';
// import UEXCase from '@/sources/articles/uexCase';

// Add new articles to this list
const articles = [
	snowflakeCoaching,
	harnessingGenerativeAIArticle,
	Snowflake,
	// DigitalOutreachPlatform,
	// PartnerWithShareShare,
	LightBulb
];

function getArticleList(locale) {
	const list = [];
	_.each(articles, (article) => {
		const {
			id, image, imageAlt, author, authorImage, date, href
		} = article;
		const {
			thumbnailTitle, thumbnailDescription
		} = article[locale];
		list.push({
			id, image, imageAlt, thumbnailTitle, thumbnailDescription, author, authorImage, date, href
		});
	});
	return list;
}

function getArticleContentList(locale) {
	const contentList = [];
	_.each(articles, (article) => {
		const { id, image } = article;
		const { title, content } = article[locale];
		contentList.push({
			id, image, title, content
		});
	});
	return contentList;
}

export {
	getArticleList,
	getArticleContentList
};
