import '../styles/DownArrow.scss';

import React from 'react';
import PropTypes from 'prop-types';

class DownArrow extends React.PureComponent {
	render() {
		const { className } = this.props;

		return (
			<div className={`down-arrow ${className}`}>
				<i className="fa fa-chevron-down" aria-hidden="true" />
			</div>
		);
	}
}

DownArrow.propTypes = {
	className: PropTypes.string
};

DownArrow.defaultProps = {
	className: ''
};

export default DownArrow;
