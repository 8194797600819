import { combineReducers } from 'redux';

// import { intlReducer } from 'react-intl-redux';
import intlReducer from './intlReducer';
import navsReducer from '../reducers/reducer_navs';
import approachesReducer from '../reducers/reducer_approaches';
import servicesReducer from '../reducers/reducer_services';
import productsReducer from './reducer_products';
import articlesReducer from '../reducers/reducer_articles';
import teamReducer from '../reducers/reducer_team';
import jobsReducer from '../reducers/reducer_jobs';
import testimonialsReducer from '../reducers/reducer_testimonials';

const rootReducer = combineReducers({
	intl: intlReducer,
	navs: navsReducer,
	approaches: approachesReducer,
	services: servicesReducer,
	products: productsReducer,
	articles: articlesReducer,
	team: teamReducer,
	jobs: jobsReducer,
	testimonials: testimonialsReducer
});

export default rootReducer;
