import '../styles/Approach.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Banner from '../components/Banner';
import ApproachCarousel from '../components/ApproachCarousel';

class ApproachComponent extends React.PureComponent {
	render() {
		const { approachList } = this.props;

		const approachListDom = approachList && approachList.length > 0
			? <ApproachCarousel slides={approachList} />
			: null;
		return (
			<div id="approach">
				<Banner className="approach">
					<div className="col-md-8 mx-auto black">

						<h4 className="page-title">
							<FormattedMessage
								id="approach.title"
							/>
						</h4>
						<p className="text-bigger">
							<FormattedMessage
								id="approach.description"
							/>
						</p>

					</div>
				</Banner>
				<div className="col-md-11 col-lg-12 mx-auto black">
					{/* Carousel */}
					{approachListDom}
				</div>
			</div>
		);
	}
}

ApproachComponent.propTypes = {
	approachList: PropTypes.arrayOf(PropTypes.object)
};

ApproachComponent.defaultProps = {
	approachList: null
};

export default ApproachComponent;
