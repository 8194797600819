import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ProductOfferings from '../components/ProductOfferings';

class ProductExploreComponent extends React.PureComponent {
	render() {
		const { exploreProductsList } = this.props;
		return (
			<div className="more-services-wrapper">
				<h4 className="page-title col-md-8 mx-auto">
					<FormattedMessage
						id="productExplore.title"
					/>
				</h4>

				{/* Explore more services */}
				<ProductOfferings productList={exploreProductsList} />
			</div>
		);
	}
}

ProductExploreComponent.propTypes = {
	exploreProductsList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ProductExploreComponent;
