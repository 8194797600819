import '../styles/Gallery.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Animation from '../components/Animation';

const odd = 2;
const even = 3;

class GalleryComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			gallery: []
		};
	}

	/*
	componentWillReceiveProps(nextProps) {
		const { gallery } = this.state;

		if (gallery.length === 0 && nextProps.images) {
			this.updateGalleries(nextProps.images);
		}
	}
	*/
	
	componentDidUpdate(prevProps) {
		if (prevProps.images !== this.props.images) {
			this.updateGalleries(this.props.images);
		}
	}

	updateGalleries(images) {
		let oddRow = true;
		const result = [];
		const imageClone = images.slice(0);

		let id = 0;
		while (imageClone.length > 0) {
			id += 1;
			result.push({
				id,
				images: imageClone.splice(0, oddRow ? odd : even)
			});
			oddRow = !oddRow;
		}

		this.setState({
			gallery: result
		});
	}

	render() {
		const { gallery } = this.state;

		return (
			<div className="gallery">
				<div className="gallery-container">
					{gallery.map((arr) => {
						let className;
						if (arr.images.length === odd) {
							className = 'col-md-6';
						} else if (arr.images.length === even) {
							className = 'col-md-4';
						} else {
							className = 'col-md-12';
						}
						return (
							<Animation key={arr.id} animate="fadeIn" once>
								{arr.images.map(image => <img key={image} className={className} src={image} alt="" />)}
							</Animation>
						);
					})}
				</div>
			</div>
		);
	}
}

GalleryComponent.propTypes = {
	images: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default GalleryComponent;
