import { FETCH_JOBS } from '../actions/jobs';

const INITIAL_STATE = { jobList: [] };

const jobsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_JOBS:
			return { ...state, jobList: action.payload };
		default:
			return state;
	}
};

export default jobsReducer;
