import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

class NavLinkComponent extends React.PureComponent {
	render() {
		const {
			className,
			activeClass,
			active,
			to,
			onClick,
			title
		} = this.props;
		return (
			<Link
				className={className + (active ? activeClass : '')}
				to={to}
				onClick={onClick}
			>
				<FormattedMessage
					id={title}
				/>
			</Link>
		);
	}
}

NavLinkComponent.propTypes = {
	title: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	className: PropTypes.string,
	activeClass: PropTypes.string,
	active: PropTypes.bool,
	onClick: PropTypes.func
};

NavLinkComponent.defaultProps = {
	className: '',
	activeClass: 'active',
	active: false,
	onClick: null
};

export default NavLinkComponent;
