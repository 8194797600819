import { connect } from 'react-redux';
// import { fetchServiceOfferings, fetchApproaches, fetchTestimonials } from '../actions';
import { fetchServiceOfferings } from '../actions/services';
import { fetchProductOfferings } from '../actions/products';
import { fetchApproaches } from '../actions/approaches';
import { fetchTestimonials } from '../actions/testimonials';
import Home from '../pages/Home';

const mapStateToProps = state => ({
    serviceList: state.services.serviceList,
    productList: state.products.productList,
    approachList: state.approaches.approachList,
    testimonialList: state.testimonials.testimonialList
});

const mapDispatchToProps = {
    fetchServiceOfferings,
    fetchProductOfferings,
    fetchApproaches,
    fetchTestimonials
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
