import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import { useLocation, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

function ScrollToTop({ children = null }) {
	const location = useLocation();
	// const navigate = useNavigate();
	const prevLocation = useRef(location);

	/*
	useEffect(() => {
		const handleScroll = () => {
			window.scrollTo(0, 0);
		};

		// Check if the action is 'PUSH' (this is how you typically navigate)
		const unlisten = navigate((_, action) => {
			if (action === 'PUSH') {
				handleScroll();
			} else if (location !== prevLocation.current) {
				animateScroll.scrollToTop();
			}
		});
		prevLocation.current = location; // Update the ref with the current location

		// Cleanup listener on component unmount
		return () => unlisten();
	}, [location, navigate]);
	*/

	useEffect(() => {
        if (location !== prevLocation.current) {
            animateScroll.scrollToTop();
            prevLocation.current = location;
        }
    }, [location]);

	return children;
}

ScrollToTop.propTypes = {
	children: PropTypes.element
};

export default ScrollToTop;

