import _ from 'lodash';

import navList from '../sources/navs';

export const FETCH_NAVS = 'FETCH_NAVS';

export function fetchNavs(smallMenuCount) {
	let filteredNavList = navList.filter(nav => !nav.hide); // filter hidded nav items

	if (smallMenuCount >= 0) {
		const smallNavList = filteredNavList.slice(0, smallMenuCount);
		let newSubLinks = [];
		_.each(filteredNavList.slice(smallMenuCount), (menu) => {
			newSubLinks.push({
				title: menu.title,
				header: true,
				anchor: menu.anchor,
				to: menu.to
			});
			if (menu.subLinks) {
				newSubLinks = newSubLinks.concat(menu.subLinks);
			}
		});
		if (newSubLinks.length > 0) {
			smallNavList.push({
				id: 'more',
				title: 'nav.more',
				to: '',
				subLinks: newSubLinks
			});
		}
		filteredNavList = smallNavList;
	}
	return {
		type: FETCH_NAVS,
		payload: filteredNavList
	};
}
