import React from 'react';
import PropTypes from 'prop-types';

import HeroBanner from '../components/HeroBanner';
import QRCoachingEvent2024Content from '../components/QRCoachingEvent2024Content';
// import Gallery from '@/components/Gallery';

import ExtendNavbar from '../components/ExtendNavbar';

// import image from '../assets/images/about-us.jpg';
import image from '../assets/images/snowflakeCoaching/joe-discuss-2-edit2.jpg';

const content = {
	image,
	title: {
		first: 'qrCoachingEvent2024.title.first',
		text: 'qrCoachingEvent2024.title.text'
	}
};

class QRCoachingEvent2024Page extends React.Component {
	render() {
		return (
		  <div className="about">
			{/* Event Hero Banner */}
			<HeroBanner content={content} />

			{/* Trigger Navbar Extension */}
			<ExtendNavbar />

			{/* Event Content */}
			<QRCoachingEvent2024Content />
		  </div>
		);
	  }
}

QRCoachingEvent2024Page.propTypes = {
	fetchTeam: PropTypes.func.isRequired,
	techTeam: PropTypes.arrayOf(PropTypes.object).isRequired,
	advisoryTeam: PropTypes.arrayOf(PropTypes.object).isRequired,
	businessTeam: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default QRCoachingEvent2024Page;
