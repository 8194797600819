import { connect } from 'react-redux';
import { fetchArticleContent } from '../actions/articles';
import Article from '../pages/Article';

const mapStateToProps = state => ({
	articleContent: state.articles.articleContent
});

const mapDispatchToProps = dispatch => ({
	fetchArticleContent: (articleId) => {
		dispatch(fetchArticleContent(articleId));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
