import '../styles/JobContact.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

class JobContactComponent extends React.PureComponent {
	render() {
		const { intl } = this.props; // Access intl from props

		const email = intl.formatMessage({ id: 'jobContact.email' });
		const subject = intl.formatMessage({ id: 'jobContact.email.subject' });

		return (
			<div className="job-contact">
				<div className="col-md-7 mx-auto">
					<p>
						<FormattedMessage
							id="jobContact.description"
						/>
						<span className="text-bold">
							<a href={`mailto:${email}?Subject=${subject}`}>{email}</a>
						</span>
					</p>
				</div>
			</div>
		);
	}
}

JobContactComponent.propTypes = {
	intl: PropTypes.object.isRequired, // Define intl in propTypes
};

export default injectIntl(JobContactComponent); // Use injectIntl to provide intl prop
