import anttiProfile from '../assets/images/antti-algebra-tiny.jpg';
import jonasProfile from '../assets/images/jonas-profile.jpg';
import chaikProfile from '../assets/images/chaikwah.jpg';
import anttinProfile from '../assets/images/anttin.png';
import sandyProfile from '../assets/images/sandy.jpg';
import sebastianProfile from '../assets/images/sebastian.jpg';
import kahyeeProfile from '../assets/images/kahyee.jpg';
import evviaProfile from '../assets/images/evvia-2-tiny.jpg';

// import sriProfile from '@/assets/images/srikanth.jpg';
// import adamProfile from '@/assets/images/adam.jpg';

import estherProfile from '../assets/images/esther-3-tiny.jpg';
// import thanhProfile from '@/assets/images/thanh.jpg';

const techTeam = [{
	id: 'antti',
	name: 'antti Johansson',
	position: 'Placeholder',
	description: 'team.antti.description',
	image: anttiProfile
}, {
	id: 'chaik',
	name: 'Khoo Chaik Wah',
	position: 'Co-founder, Software Delivery & Agile Consulting',
	description: 'team.chaik.description',
	image: chaikProfile
}, {
	id: 'anttin',
	name: 'Antti Nissinen',
	position: 'Digital Artist & UI/UX Designer',
	description: 'team.anttin.description',
	image: anttinProfile
}, {
	id: 'sandy',
	name: 'Sandy Ong',
	position: 'Software Developer and Product Specialist',
	description: 'team.sandy.description',
	image: sandyProfile
}, {
	id: 'sebastian',
	name: 'Sebastian Sim',
	position: 'Software Developer and Product Specialist',
	description: 'team.sebastian.description',
	image: sebastianProfile
}, {
	id: 'kahyee',
	name: 'Kah Yee',
	position: 'Software Developer and Product Specialist',
	description: 'team.kahyee.description',
	image: kahyeeProfile
}];

const advisoryTeam = [{
	id: 'jonas',
	name: 'Jonas Larsson',
	position: 'Founder, Business Technology Advisor'
}, {
	id: 'sri',
	name: 'Srikanth',
	position: 'Co-founder, Technology Advisor'
	// description: 'team.sri.description',
	// image: sriProfile
}, {
	id: 'adam',
	name: 'Adam Soh',
	position: 'Co-founder, UI/UX Design Advisor'
	// description: 'team.adam.description',
	// image: adamProfile
}];

const businessTeam = [{
	id: 'esther',
	name: 'Esther Johansson',
	position: 'Co-Founder, Director and Head of Business Development',
	description: 'team.esther.description',
	image: estherProfile,
	email: 'esther@algebratek.com'
}, {
	id: 'evvia',
	name: 'Evvia Veno',
	position: 'Co-Founder, COO and Head of Client Services',
	description: 'team.evvia.description',
	image: evviaProfile,
	email: 'evvia@algebratek.com'
}, {
	id: 'antti',
	name: 'Antti Johansson',
	position: 'CTO, Agile Team Coach, Principal Engineer',
	description: 'team.antti.description',
	image: anttiProfile,
	email: 'antti@algebratek.com'
}, {
	id: 'jonas',
	name: 'Jonas Larsson',
	position: 'Adviser, Technologist, Architecture Strategist',
	description: 'team.jonas.description',
	image: jonasProfile
}];
/* , {
	id: 'thanh',
	name: 'Thanh Randall',
	position: 'Client Services Director',
	description: 'team.thanh.description',
	image: thanhProfile,
	email: 'thanh.randall@dtaltechnologies.com'
}]; */

const teamList = {
	techTeam, advisoryTeam, businessTeam
};

export default teamList;
