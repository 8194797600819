import { articleId, paths } from '../../sources/constant';

import coverImage from '../../assets/images/snowflake/snowflake-cover.jpg';
import anttiProfileImage from '../../assets/images/antti-thumbnail.jpg';
import teachersFeedbackImage from '../../assets/images/snowflake/snowflake-teachers-feedback.jpg';
import girlImage from '../../assets/images/snowflake/snowflake-girl.jpg';
import controlNeedsImage from '../../assets/images/snowflake/snowflake-control-needs.png';
import coCreateImage from '../../assets/images/snowflake/snowflake-co-create.jpg';

const snowflakeArticle = {
	id: articleId.SNOWFLAKE,
	image: coverImage,
	imageAlt: 'Snowflake Special Education Platform',
	author: 'Algebra with Dtal Technologies',
	authorImage: anttiProfileImage,
	date: '19 jun 2019',
	href: paths.SNOWFLAKE,
	en: {
		title: {
			first: '',
			text: 'Snowflake Special Education Platform',
			translate: false
		},
		thumbnailTitle: 'Snowflake Special Education Platform',
		thumbnailDescription: 'Special needs educators are at high risk of burn-out. But why is that? What do the teachers say?',
		content: [{
			html: ` <h4 class="page-title">Special Education in new way</h4>
					<p>Special needs educators are at high risk of burn-out.</p>
					<p>But why is that? What do the teachers say?</p>
					<div class="row img-wrapper">
						<div class="col-12 col-lg-11 mx-auto">
							<p><img src=${teachersFeedbackImage} alt="Teachers" class="img-fluid"/></p>
						</div>
					</div>

					<h5 class="subtitle">How could we create systems that truly help those working with the children, yet solving the challenges that running education organization and caring for children do face?</h5>
					<p>We are used to hearing terms like LMS and SIS or even CIS.</p>
					<p>Then challenge with these approaches is that they come largely from organizational viewpoint and leaving those engaging most closely with the Special Needs children into secondary role.</p>

					<h5 class="subtitle">What makes special education different?</h5>
					<p>All teachers have paperwork and they all carry it home, so what makes special needs educators different?</p>
					<p>Each child need to have their unique education plan, because their situations are individually different. Creating such individual plans adds significant workload.</p>
					<p>Each child’s issues may have various dimensions that need professional involvement for various disciplines. To cater for this a trans-disciplinary team works together to assess and come up with plans for the child. This greatly helps the child in with special needs, yet this is another source for added workload for teachers and professionals. Co-ordination, team communication, alignment and documentation all take time and effort – for good intention, but away from engaging with the child.</p>
					<p>As learning takes place in learning groups, yet children have individual plans and needs lesson plans require further data gathering and co-ordination. Teachers need to establish cross group views per learning objective so as to create lessons.</p>
					<p>When executing teaching capturing progress of each child becomes important so that what works and what does not will be known and communicated, and general progress of learning groups becomes observable for the organization. Again teacher needs to provide this information.</p>

					<h5 class="subtitle">Digitizing the workflow is not enough.</h5>
					<p>We might think that just by introducing a system to capture this data would help. However, doing the same things digitally does not make much difference for teachers.</p>
					<p>Teachers would still need to produce tens of reports and communicate them across. In worst case such systems would add more red tape to control approvals and process more rigidly than when operating manually with certain degree of flexibility.</p>
					<div class="row img-wrapper">
						<div class="col-12 col-lg-11 mx-auto">
							<p><img src=${girlImage} alt="Child" class="img-fluid"/></p>
						</div>
					</div>
					<ul>
						<li>How could we empower those using new digital tools in their work with special needs children?</li>
						<li>How could we help those doing the work while at the same time helping organizations in their mission?</li>
						<li>Isn’t it so that these objectives are aligned, when we cater for the perspectives and needs of both?</li>
					</ul>

					<h5 class="subtitle">Re-inventing how we work.</h5>
					<p>People using such systems designed from above become slaves of the system, rather than system working as a tool helping the people.</p>
					<p>System rigidity may even add to the stress that teachers and professionals experience in their work.</p>
					<br>
					<p>Organizational processes typically trace the process of a child engaging with the school. Or more granularly focusing on parts of that process such as admission, IEP process and others.</p>
					<p>Through such processes it is possible to map organizational 'requirements' and create corresponding systems with full formal compliance.</p>
					<p>However, what typically is lacking with processes is the experience of a teacher or therapist as they go through their daily work. There are frequent touch points with processes, yet their workflow and experience is distinctly different.</p>
					<div class="row img-wrapper">
						<div class="col-12 col-lg-11 mx-auto">
							<p><img src=${controlNeedsImage} alt="Considerations" class="img-fluid"/></p>
						</div>
					</div>
					<p>When building Snowflake the starting point has been to focus on teachers and professionals work experience on top of the background of organizational processes and needs.</p>
					<p>Those using the system and their work situation should drive the experience system provides. Snowflake was built using design thinking practices with teachers, professionals and school leadership, experimenting with the solution and iterating to reach state that truly serves all the users and stakeholders.</p>

					<p class="text-bolditalic">Snowflake for Special Education</p>
					<div class="row img-wrapper">
						<div class="col-12 col-lg-11 mx-auto">
							<p><img src=${coCreateImage} alt="Co-created for Special Needs" class="img-fluid"/></p>
						</div>
					</div>
					<p>Want to find out more about special education solutions or how you could be part of the journey?</p>
					<p>Or learn more how design thinking and agile way of working can help create new solutions?</p>
			`
		}]
	}
};

export default snowflakeArticle;
