import '../styles/JobReasons.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

import Divider from '../components/Divider';

import passionImage from '../assets/images/jobs-passion.png';
import diverseImage from '../assets/images/jobs-inclusion.png';
import mentorImage from '../assets/images/jobs-mentorship.png';
import benefitsImage from '../assets/images/jobs-benefits.png';

const reasons = [{
    id: 1,
    image: passionImage,
    title: 'jobReasons.passion.title',
    description: 'jobReasons.passion.description'
}, {
    id: 2,
    image: diverseImage,
    title: 'jobReasons.diverse.title',
    description: 'jobReasons.diverse.description'
}, {
    id: 3,
    image: mentorImage,
    title: 'jobReasons.mentor.title',
    description: 'jobReasons.mentor.description'
}, {
    id: 4,
    image: benefitsImage,
    title: 'jobReasons.benefits.title',
    description: 'jobReasons.benefits.description'
}];

class JobReasonsComponent extends React.PureComponent {
    render() {
        const { intl } = this.props; // Access intl from props

        return (
            <div className="job-reasons">
                <div className="col-md-8 mx-auto">
                    <h4 className="page-title">
                        <FormattedMessage
                            id="jobReasons.title"
                        />
                    </h4>

                    <div className="row">
                        {reasons.map(reason => (
                            <div key={reason.id} className="job-reason col-md-6">
                                <img src={reason.image} className="job-img" role="presentation" alt={intl.formatMessage({ id: reason.title })} />
                                <h6 className="text-bold brown">
                                    <FormattedMessage
                                        id={reason.title}
                                    />
                                </h6>
                                <p>
                                    <FormattedMessage
                                        id={reason.description}
                                    />
                                </p>
                            </div>
                        ))}
                    </div>

                    <Divider className="job-divider" />
                </div>
            </div>
        );
    }
}

JobReasonsComponent.propTypes = {
    intl: PropTypes.object.isRequired // Define intl in propTypes
};

export default injectIntl(JobReasonsComponent); // injectIntl automatically provides the intl prop
