import { FETCH_APPROACHES } from '../actions/approaches';

const INITIAL_STATE = { approachList: [] };

const approachesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_APPROACHES:
			return { ...state, approachList: action.payload };
		default:
			return state;
	}
};

export default approachesReducer;
