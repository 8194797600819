import '../styles/AboutContent.scss';

import React from 'react';
// import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import slide4 from '../assets/images/transform-build-product.png';

function AboutContentComponent() {
	return (
		<div className="about-container intro-overlap col-12 col-lg-9 mx-auto">
			<div className="intro-content">
				<div className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="about.intro.title"
						/>
					</h4>
					<p>
						<FormattedMessage
							id="about.intro.text1"
						/>
					</p>
					<p>
						<FormattedMessage
							id="about.intro.text2"
						/>
					</p>
				</div>
				<h3 className="col-12 col-md-9 h2 mx-auto about-quotes text-center">
					<FormattedMessage
						id="about.intro.quote"
					/>
				</h3>
				<div className="about-image row">
					<div className="col-11 col-md-6 text-center">
						<img src={slide4} alt="From transformation to first version" />
					</div>
				</div>
				<section className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="about.story.title"
						/>
					</h4>
					<p>
						<FormattedMessage
							id="about.story.text"
						/>
					</p>
				</section>
			</div>
		</div>
	);
}

AboutContentComponent.propTypes = {
};

export default AboutContentComponent;
