import {
	FETCH_SERVICE_OFFERINGS,
	FETCH_SERVICE,
	FETCH_SERVICE_EXPLORE,
	FETCH_SERVICE_DESCRIPTION
} from '../actions/services';

const INITIAL_STATE = {
	serviceList: [],
	service: null,
	exploreServicesList: [],
	serviceDescription: null,
};

const servicesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_SERVICE_OFFERINGS:
			return { ...state, serviceList: action.payload };
		case FETCH_SERVICE:
			return { ...state, service: action.payload };
		case FETCH_SERVICE_EXPLORE:
			return { ...state, exploreServicesList: action.payload };
		case FETCH_SERVICE_DESCRIPTION:
			return { ...state, serviceDescription: action.payload };
		default:
			return state;
	}
};

export default servicesReducer;
