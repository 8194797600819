import { connect } from 'react-redux';
import QRCoachingEvent2024 from '../pages/QRCoachingEvent2024';

// If you don't need to map any state or dispatch any actions, you can leave them as empty objects
const mapStateToProps = state => ({
  // No state to map for now
});

const mapDispatchToProps = dispatch => ({
  // No actions to dispatch for now
});

// Connect the component with Redux, even if it's not using any props for now
export default connect(mapStateToProps, mapDispatchToProps)(QRCoachingEvent2024);
