import { productId } from '../sources/constant';

//Update images per service here:
// import image1 from '../assets/images/new-product-discovery.jpg';
import image1 from '../assets/images/snowflake/snowflake-girl.jpg';
import image2 from '../assets/images/coaching-system-algebra-tiny.jpg';
import image3 from '../assets/images/algebra-MMS-tiny.jpg';
import image4 from '../assets/images/DALLE-website-engine-tiny.jpg';

const productList = [{
	id: productId.CURRICULUM_SYSTEM,
	title: {
		first: 'productOffering.snowflake',
		text: 'productOffering.educationPlatform'
	},
	description: 'productOffering.curriculumSystemDescription',
	image: image1,
	imageAlt: 'Curriculum System',
	link: {
		text: 'productOffering.curriculumSystemlink'
	}
}, {
	id: productId.COACHING_SYSTEM,
	title: {
		first: 'productOffering.coaching',
		text: 'productOffering.system'
	},
	description: 'productOffering.coachingSystemDescription',
	image: image2,
	imageAlt: 'Coaching System',
	link: {
		text: 'productOffering.coachingSystemlink'
	}
}, {
	id: productId.MEMBERSHIP_SYSTEM,
	title: {
		first: 'productOffering.membership',
		text: 'productOffering.managementSystem'
	},
	description: 'productOffering.membershipSystemDescription',
	image: image3,
	imageAlt: 'Membership System',
	link: {
		text: 'productOffering.membershipSystemlink'
	}
}, {
	id: productId.WEBSITE_ENGINE,
	title: {
		first: 'productOffering.website',
		text: 'productOffering.engine'
	},
	description: 'productOffering.websiteEngineDescription',
	image: image4,
	imageAlt: 'Website Engine',
	link: {
		text: 'productOffering.websiteEnginelink'
	}
}];

export default productList;
