import { connect } from 'react-redux';
import { fetchTeam } from '../actions/team';
import About from '../pages/About';

const mapStateToProps = state => ({
	techTeam: state.team.techTeam,
	advisoryTeam: state.team.advisoryTeam,
	businessTeam: state.team.businessTeam
});

const mapDispatchToProps = dispatch => ({
	fetchTeam: () => {
		dispatch(fetchTeam());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
