const constants = {
	smallestScreenSize: 320,
	smallerScreenSize: 480,
	smallScreenSize: 576,
	mediumScreenSize: 768,
	RECAPTCHA_SITE_KEY: '6Lf4gKoUAAAAAPcgtgjjt8oYPGUCgjPS_vjm1DKP',
	EMAIL_JS: {
		SERVICE_ID: 'service_lcd64ws', // 'gmail',
		TEMPLATE_ID: 'template_oedc1ih', // 'template_dtal_website',
		USER_ID: 'nAfeKgbVSAsk2WQ23' // 'user_Ny0Pny6rkOQNKZnqiPVda'
	}
};

export default Object.freeze(Object.assign({}, constants));
