import '../styles/ServiceContent.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, IntlContext } from 'react-intl'; // Import IntlContext for modern context API
import { Link } from 'react-router-dom';

import HtmlTransform from '../components/HtmlTransform';
import ServiceCarousel from '../components/ServiceCarousel';

class ServiceContentComponent extends React.Component {
    static contextType = IntlContext; // Use the modern context API

    static showFormattedMessage(id) {
        if (id) {
            return (
                <FormattedMessage
                    id={id}
                />
            );
        }
        return null;
    }

    static showCarousel(slides, key) {
        return (
            <ServiceCarousel slides={slides} key={key} />
        );
    }

    static showCaseStudy(caseStudy) {
        if (caseStudy) {
            return caseStudy.map((study) => {
                const caseStudyStyle = {
                    backgroundImage: `url(${study.image})`
                };
                return (
                    <Link to={study.link} key={study.id}>
                        <div className="service-case-study" style={caseStudyStyle}>
                            <div className="service-case-study-content">
                                <p className="white text-regular">{study.title}</p>
                                <p className="white h4">{study.desc}</p>
                            </div>
                        </div>
                    </Link>
                );
            });
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.showFormattedMessage = ServiceContentComponent.showFormattedMessage;
        this.showCarousel = ServiceContentComponent.showCarousel;
        this.showCaseStudy = ServiceContentComponent.showCaseStudy;
    }

    render() {
        const { serviceDescription } = this.props;

        let content = this.showFormattedMessage('error.noDescription');
        if (serviceDescription && serviceDescription.intro) {
            content = (
                <div>
                    <div className="intro-content">
                        <div className="col-md-11 mx-auto">
                            <h4 className="page-title">
                                {this.showFormattedMessage(serviceDescription.intro.title)}
                            </h4>
                            <p>
                                {this.showFormattedMessage(serviceDescription.intro.text)}
                            </p>
                        </div>
                    </div>
                    <div className="service-content">
                        <HtmlTransform className="col-md-11 mx-auto" html={serviceDescription.content} showCarousel={this.showCarousel} />

                        <div className="col-md-11 mx-auto">
                            {this.showCaseStudy(serviceDescription.caseStudy)}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="service-container intro-overlap col-12 col-lg-9 mx-auto">
                {content}
            </div>
        );
    }
}

ServiceContentComponent.propTypes = {
    serviceDescription: PropTypes.shape({
        id: PropTypes.string.isRequired,
        intro: PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string
        }),
        content: PropTypes.arrayOf(PropTypes.shape({
            html: PropTypes.string
        })),
        caseStudy: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            desc: PropTypes.string,
            image: PropTypes.string,
            link: PropTypes.string
        }))
    })
};

ServiceContentComponent.defaultProps = {
    serviceDescription: null
};

export default ServiceContentComponent;
