import { connect } from 'react-redux';
import { fetchService, fetchServiceDescription, fetchServiceExplore } from '../actions/services';
import Service from '../pages/Service';

const mapStateToProps = state => ({
	service: state.services.service,
	serviceDescription: state.services.serviceDescription,
	exploreServicesList: state.services.exploreServicesList
});

const mapDispatchToProps = dispatch => ({
	fetchService: (serviceId) => {
		dispatch(fetchService(serviceId));
	},
	fetchServiceDescription: (serviceId) => {
		dispatch(fetchServiceDescription(serviceId));
	},
	fetchServiceExplore: (serviceId, itemsToDisplay) => {
		dispatch(fetchServiceExplore(serviceId, itemsToDisplay));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Service);
