import '../styles/App.scss';

import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes

import NavContainer from '../containers/NavContainer'; // Import NavContainer
import ContactFormContainer from '../containers/ContactFormContainer'; // Import ContactFormContainer
import Footer from '../components/Footer'; // Import Footer

export const NavbarContext = createContext();

const AppContainer = ({ children }) => {
    const [extend, setExtend] = useState(false);

    const extendNavbarBorder = useCallback((waypointPos) => {
        if (waypointPos.currentPosition === 'above') {
            setExtend(true);
        } else {
            setExtend(true);
        }
    }, []);

    return (
        <NavbarContext.Provider value={{ extendNavbarBorder }}>
            <div className="index">
                {/* Nav */}
                <NavContainer extend={extend} />

                {children}

                {/* Contact Form */}
                <ContactFormContainer />

                {/* Footer */}
                <Footer />
            </div>
        </NavbarContext.Provider>
    );
};

AppContainer.propTypes = {
    children: PropTypes.element.isRequired
};

export default AppContainer;
