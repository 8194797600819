import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ProductOfferings from '../components/ProductOfferings';

class ProductsComponent extends React.PureComponent {
	render() {
		const { productList } = this.props;

		return (
			<div id="services" className="services">
				<div className="col-md-8 mx-auto">
					<h4 className="services-title page-title">
						<FormattedMessage
							id="products.title"
						/>
					</h4>
					<p className="text-bigger black">
						<FormattedMessage
							id="products.description"
						/>
					</p>
				</div>

				{/* Service Offerings */}
				<ProductOfferings productList={productList} />
			</div>
		);
	}
}

ProductsComponent.propTypes = {
	productList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ProductsComponent;
