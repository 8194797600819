// Import locale data
import localeData from '../locales/data.json';

// Dynamically import the correct store configuration based on environment
let configureStore;

// console.log(process.env.NODE_ENV)


if (process.env.NODE_ENV === 'production') {
    configureStore = require('../stores/dist').default;
} else {
    configureStore = require('../stores/dev').default;
}

// console.log(configureStore)

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these differences by checking all of them
const language = (navigator.languages && navigator.languages[0])
    || navigator.language
    || navigator.userLanguage;

// Split locales with a region code
const languageWithoutRegionCode = 'en'; // Replace with dynamic detection if needed

// Try full locale, fallback to locale without region code, fallback to en
const messages = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en;

// console.log(messages)
// console.log(messages['contactForm.placeholder.message'])

// Initial state including internationalization data
const initialState = {
    intl: {
        locale: languageWithoutRegionCode,
        messages
    }
};

// Create the store with the initial state
export default configureStore(initialState);