import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchNavs } from '../actions/navs';
import Nav from '../components/Nav';

const mapStateToProps = state => ({
    navList: state.navs.navList
});

const mapDispatchToProps = dispatch => ({
    fetchNavs: (smallMenuCount) => {
        dispatch(fetchNavs(smallMenuCount));
    }
});

// Wrap the Nav component to inject router props
const NavContainer = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Nav {...props} location={location} navigate={navigate} params={params} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(NavContainer);
