import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

class HtmlTransformComponent extends React.Component {
    constructor(props) {
        super(props);

        this.formatText = this.formatText.bind(this);
        this.htmlSplitSliderArr = this.htmlSplitSliderArr.bind(this);
        this.showHTMLContent = this.showHTMLContent.bind(this);
    }

    formatText(html) {
        const { intl } = this.props;

        return html.replace(/{[ ]?@format:[ ]?([^}])*[ ]?}/g, (text) => {
            const split = text.substring(text.indexOf(':') + 1, text.lastIndexOf('}')).trim();
            return intl.formatMessage({ id: split });
        });
    }

    htmlSplitSliderArr(html) {
        const formattedHtml = this.formatText(html);
        const { showCarousel } = this.props;
        let htmlArr = formattedHtml.split(/({[ ]?@slider:[ ]?([^}])*[ ]?})/g).filter(text => !!text.trim());
        htmlArr = _.map(htmlArr, (text, i) => {
            let newText = text;
            if (showCarousel && text.indexOf('@slider') > -1) {
                const split = text.substring(text.indexOf(':') + 1, text.lastIndexOf('}')).trim();
                let slides = split.split('$');
                slides = slides.filter(Boolean);
                newText = showCarousel(slides, i);
            }
            return newText;
        });
        return htmlArr;
    }

    showHTMLContent(contentArray) {
        if (contentArray && contentArray.length > 0) {
            return contentArray.map((content) => {
                const htmlArr = this.htmlSplitSliderArr(content.html);
                return htmlArr.map((html) => {
                    if (_.isString(html)) {
                        return (
                            <div
                                key={html}
                                dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
                            />
                        );
                    }
                    return (html);
                });
            });
        }
        return null;
    }

    render() {
        const { className, html } = this.props;
        return <div className={className}>{this.showHTMLContent(html)}</div>;
    }
}

HtmlTransformComponent.propTypes = {
    intl: PropTypes.object.isRequired, // This will now come from injectIntl
    className: PropTypes.string,
    html: PropTypes.arrayOf(PropTypes.shape({
        html: PropTypes.string
    })),
    showCarousel: PropTypes.func
};

HtmlTransformComponent.defaultProps = {
    className: '',
    html: null,
    showCarousel: null
};

// Wrap your component with injectIntl to inject the intl prop
export default injectIntl(HtmlTransformComponent);
