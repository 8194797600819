import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const TeamProfile = ({
	name, position, description, image, email
}) => {
	const imageSection = (
		<div className="col-5 col-md-4 col-lg-3">
			<img className="team-profile-image" src={image} alt={name} />
		</div>
	);
	const descriptionSection = (
		<div className={`col-12${image ? ' col-lg-8 offset-lg-1' : ''}`}>
			<h6>{name}</h6>
			<p className="team-position brown">
				{position}
			</p>
			{description && (
				<p className="profile-desc">
					<FormattedMessage
						id={description}
					/>
				</p>
			)}
			{email && (<p><a href={`mailto:${email}`}>{email}</a></p>)}
		</div>
	);
	return (
		<div className={`team-member col-md-11 mx-auto${image ? ' has-image' : ''}`}>
			<div className="row">
				{image && imageSection}
				{descriptionSection}
			</div>
		</div>
	);
};

TeamProfile.propTypes = {
	name: PropTypes.string.isRequired,
	position: PropTypes.string.isRequired,
	description: PropTypes.string,
	image: PropTypes.string,
	email: PropTypes.string
};

TeamProfile.defaultProps = {
	description: '',
	image: null,
	email: null
};

export default TeamProfile;
