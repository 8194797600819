import { connect } from 'react-redux';
import { fetchProduct, fetchProductDescription, fetchProductExplore } from '../actions/products';
import Product from '../pages/Product';

const mapStateToProps = state => ({
	product: state.products.product,
	productDescription: state.products.productDescription,
	exploreProductsList: state.products.exploreProductsList
});

const mapDispatchToProps = dispatch => ({
	fetchProduct: (productId) => {
		dispatch(fetchProduct(productId));
	},
	fetchProductDescription: (productId) => {
		dispatch(fetchProductDescription(productId));
	},
	fetchProductExplore: (productId, itemsToDisplay) => {
		dispatch(fetchProductExplore(productId, itemsToDisplay));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
