import '../styles/Article.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import HeroBanner from '../components/HeroBanner';
import ArticleContent from '../components/ArticleContent';
import ExtendNavbar from '../components/ExtendNavbar';

const ArticlePage = ({ fetchArticleContent, articleContent = null }) => { // Set a default value here
    const { articleId } = useParams(); // Use useParams to get articleId from the URL

    useEffect(() => {
        fetchArticleContent(articleId); // Fetch the article content when the component mounts or articleId changes
    }, [articleId, fetchArticleContent]);

    return (
        <div className="article">

            {/* Article Hero */}
            <HeroBanner content={articleContent} />

            {/* Trigger Navbar Extension */}
            <ExtendNavbar />

            {/* Article Content */}
            <ArticleContent articleContent={articleContent} />
        </div>
    );
};

ArticlePage.propTypes = {
    fetchArticleContent: PropTypes.func.isRequired,
    articleContent: PropTypes.shape(),
};

// Remove defaultProps since we set the default directly in the function parameter
export default ArticlePage;
