import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import HeroBanner from '../components/HeroBanner';
import ProductContent from '../components/ProductContent';
import ProductExplore from '../components/ProductExplore';

import ExtendNavbar from '../components/ExtendNavbar';

const itemsToDisplay = 3;

const ProductPage = ({ 
    fetchProduct, 
    fetchProductDescription, 
    fetchProductExplore, 
    product = null, 
    productDescription = null, 
    exploreProductsList = null 
}) => {
    const { productId } = useParams();

    useEffect(() => {
        if (productId) {
            fetchProduct(productId);
            fetchProductDescription(productId);
            fetchProductExplore(productId, itemsToDisplay);
        }
    }, [productId, fetchProduct, fetchProductDescription, fetchProductExplore]);

    // For products we share styling and some components with services to display the formatted content
    return (
        <div className="service">
            {/* Product Hero */}
            <HeroBanner content={product} />

            {/* Trigger Navbar Extension */}
            <ExtendNavbar />

            {/* Product Content */}
            <ProductContent productDescription={productDescription} />

            {/* Explore More Products */}
            <ProductExplore exploreProductsList={exploreProductsList} />
        </div>
    );
};

ProductPage.propTypes = {
    fetchProduct: PropTypes.func.isRequired,
    fetchProductDescription: PropTypes.func.isRequired,
    fetchProductExplore: PropTypes.func.isRequired,
    product: PropTypes.shape(),
    productDescription: PropTypes.shape(),
    exploreProductsList: PropTypes.arrayOf(PropTypes.object)
};

export default ProductPage;
