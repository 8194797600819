import { articleId, paths } from '../../sources/constant';

// import lightBulbImage from '@/assets/images/article-bulb-cover.jpg';
// import harnessingGenerativeAIImage from '../../assets/images/harnessingGenerativeAI/harnessing-generative-ai-cover.jpg';
import harnessingGenerativeAIImage from '../../assets/images/harnessingGenerativeAI/DALLE-harnessing-generative-ai-partner-2.jpg';
import anttiProfileImage from '../../assets/images/antti-algebra-thumbnail.jpg';
import articleHarnessingGenerativeAI from '../../assets/images/harnessingGenerativeAI/harnessing-generative-ai-1.jpg';

const harnessingGenerativeAIArticle = {
	id: articleId.HARNESSING_GENERATIVE_AI,
	image: harnessingGenerativeAIImage,
	imageAlt: 'AI Generating',
	author: 'Algebra with Dtal Technologies',
	authorImage: anttiProfileImage,
	date: '6 may 2024',
	href: paths.HARNESSING_GENERATIVE_AI,
	en: {
		title: {
			first: 'Harnessing',
			text: 'Generative AI',
			translate: false
		},
		thumbnailTitle: 'Harnessing Generative AI',
		thumbnailDescription: 'Explore practical strategies to integrate Generative AI into business processes, enhancing efficiency and tailoring solutions to industry needs.',
		content: [{
			html: ` <h4 class="page-title">Harnessing Generative AI</h4>
					<p><strong>Generative AI</strong> is revolutionizing industry applications, yet integrating this advanced technology effectively into business processes presents substantial challenges. Understanding the right approach to embedding AI tools is crucial for maximizing their potential.</p>
					<h5 class="subtitle">Generative AI Across Industries</h5>
					<p>Generative AI offers a transformative approach applicable across various fields, with software development being notably impacted. However, significant questions arise when considering its application:</p>
                    <ul>
                        <li>Is it necessary to build a generative AI model from scratch for my specific domain?</li>
                        <li>Can generative AI services comprehend my unique context without bespoke training?</li>
                    </ul>
					<h5 class="subtitle">Understanding AI Models</h5>
					<p>AI derives its capabilities from underlying models trained on vast datasets, requiring immense computational power and time. Such extensive training is often beyond the reach of all but the largest corporations and government entities.</p>
					<h5 class="subtitle">Practical Approaches to AI Integration</h5>
                    <ul>
                        <li><strong>Augmented Training</strong>: One feasible strategy is to employ a general AI model, augmenting it with your specific data. This approach demands substantial data and investment but is more accessible than starting anew.</li>
                        <li><strong>Contextual Setup</strong>: Alternatively, set the context for the AI at the point of engagement, feeding it with specific details and contextual information. This method leverages the adaptability of generative AI to understand and apply context directly relevant to the task at hand.</li>
                    </ul>

					<div class="row img-wrapper">
						<div class="col-12 col-lg-11 mx-auto">
							<p><img src=${articleHarnessingGenerativeAI} alt="lightings" class="img-fluid"/></p>
						</div>
					</div>

					<h5 class="subtitle">Implementing Generative AI</h5>
                    <p>Implementing these strategies allows businesses to harness the power of AI effectively, aligning it with specific needs without the prohibitive cost of full model training. This hybrid approach not only enhances efficiency but also ensures that generative AI tools are directly applicable to the unique challenges and processes of your business.</p>

                    <p>For further information on applying AI within your operations or to discuss deploying a tailored AI solution, please reach out to our team.</p>`
		}]
	}
};

export { harnessingGenerativeAIArticle };
