const juniorAndSeniorConsultantJob = {
	id: 1,
	content: [{
		html: ` <h6 class="jobs-title hide"><span class="brown">{@format: jobs.juniorAndSeniorConsultant.position}</span></h6>
		<p class="jobs-desc">{@format: jobs.juniorAndSeniorConsultant.desc1}</p>
		<p class="jobs-desc">{@format: jobs.juniorAndSeniorConsultant.desc2}</p>
		<p class="jobs-desc">{@format: jobs.juniorAndSeniorConsultant.desc3}</p>
		<ul class="jobs-item-wrapper">
			<li>{@format: jobs.juniorAndSeniorConsultant.requirements.point1}</li>
			<li>{@format: jobs.juniorAndSeniorConsultant.requirements.point2}</li>
			<li>{@format: jobs.juniorAndSeniorConsultant.requirements.point3}</li>
			<li>{@format: jobs.juniorAndSeniorConsultant.requirements.point4}</li>
		</ul>
		`
	}]
};

export default juniorAndSeniorConsultantJob;
