import { connect } from 'react-redux';
import { fetchArticles } from '../actions/articles';
import Articles from '../pages/Articles';

const mapStateToProps = state => ({
	articleList: state.articles.articleList
});

const mapDispatchToProps = dispatch => ({
	fetchArticles: () => {
		dispatch(fetchArticles());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
