import React from 'react';
import { Routes, Route, Navigate, BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import App from './components/AppContainer';
import HomeContainer from './containers/HomeContainer';
import ServiceContainer from './containers/ServiceContainer';
import ProductContainer from './containers/ProductContainer';
import ArticlesContainer from './containers/ArticlesContainer';
import ArticleContainer from './containers/ArticleContainer';
import AboutContainer from './containers/AboutContainer';
import JobsContainer from './containers/JobsContainer';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import QRCoachingEvent2024Container from './containers/QRCoachingEvent2024Container';


function RoutesComponent() {
    return (
        <Router>
            <ScrollToTop>
                <App>
                    <Routes>
                        <Route path="/" element={<HomeContainer />} />
                        <Route path="/services/:serviceId" element={<ServiceContainer />} />
                        <Route path="/products/:productId" element={<ProductContainer />} />
                        <Route path="/articles" element={<ArticlesContainer />} />
                        <Route path="/articles/:articleId" element={<ArticleContainer />} />
                        <Route path="/about" element={<AboutContainer />} />
                        <Route path="/jobs" element={<JobsContainer />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="*" element={<Navigate to="/" />} />
                        <Route path="/qr-coaching-event-2024" element={<QRCoachingEvent2024Container />} />
                    </Routes>
                </App>
            </ScrollToTop>
        </Router>
    );
}

export default RoutesComponent;
