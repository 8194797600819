import React from 'react';
import PropTypes from 'prop-types';

import HtmlTransform from '../components/HtmlTransform';

class ArticleContentComponent extends React.PureComponent {
	render() {
		const { articleContent } = this.props;
		if (articleContent) {
			return (
				<div className="intro-overlap col-12 col-lg-9 mx-auto">
					<div className="intro-content">
						<HtmlTransform className="col-md-11 mx-auto" html={articleContent.content} />
					</div>
				</div>
			);
		}
		return null;
	}
}

ArticleContentComponent.propTypes = {
	articleContent: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.arrayOf(PropTypes.shape({
			html: PropTypes.string
		}))
	})
};

ArticleContentComponent.defaultProps = {
	articleContent: null
};

export default ArticleContentComponent;
