import React, { useState, useEffect, useRef, useContext } from 'react';
// import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import * as emailjs from 'emailjs-com';
import { useLocation } from 'react-router-dom';
import { IntlContext } from 'react-intl'; 
import constants from '../constants';

const ContactForm = (props) => {
    const [fromName, setFromName] = useState('');
    const [fromEmail, setFromEmail] = useState('');
    const [message, setMessage] = useState('');
    const [captcha, setCaptcha] = useState(null);
    const [alert, setAlert] = useState('hidden');
    // const { intl } = useContext(props.context);
	const intl = useContext(IntlContext); 

    const location = useLocation(); // Using useLocation hook to get location
	const timeoutRef = useRef(null); // Use a ref to store the timeout

    useEffect(() => {
        return () => clearTimeout(timeoutRef.current); // Cleanup timeout on unmount
    }, []);

    const handleSuccess = () => {
        clearState();
        showAlert('success');
    };

    const handleError = () => {
        showAlert('error');
    };

    const showAlert = (newAlertState) => {
        setAlert(newAlertState);
        timeoutRef.current = setTimeout(() => setAlert('hidden'), 3000);
    };

    const clearState = () => {
        setFromName('');
        setFromEmail('');
        setMessage('');
    };

    const submit = (e) => {
        e.preventDefault();

        if (isDisabled()) return;

        const { EMAIL_JS: { SERVICE_ID, TEMPLATE_ID, USER_ID } } = constants;
        const { host } = window.location;

        const templateParams = {
            from_name: fromName,
            from_email: fromEmail,
            reply_to: fromEmail,
            message,
            'g-recaptcha-response': captcha,
            from_page: host + location.pathname
        };

        // TODO: move to actions
        emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
            .then(handleSuccess, handleError);
    };

    const isDisabled = () => {
        return !fromName.trim() || !fromEmail.trim() || !message.trim() || !captcha;
    };

    return (
        <form id="contactForm" className="col-md-8 mx-auto mb-5 mt-5" onSubmit={submit}>
            <h4 className="page-title black">
                {intl.formatMessage({ id: 'contactForm.title' })}
            </h4>

            <div className="form-group">
                <label htmlFor="from_name">
                    {intl.formatMessage({ id: 'contactForm.label.name' })}
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="from_name"
                    name="from_name"
                    placeholder={intl.formatMessage({ id: 'contactForm.placeholder.name' })}
                    value={fromName}
                    onChange={(e) => setFromName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="from_email">
                    {intl.formatMessage({ id: 'contactForm.label.email' })}
                </label>
                <input
                    type="email"
                    className="form-control"
                    id="from_email"
                    name="from_email"
                    placeholder={intl.formatMessage({ id: 'contactForm.placeholder.email' })}
                    value={fromEmail}
                    onChange={(e) => setFromEmail(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="message">
                    {intl.formatMessage({ id: 'contactForm.label.message' })}
                </label>
                <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    placeholder={intl.formatMessage({ id: 'contactForm.placeholder.message' })}
                    rows="5"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <ReCAPTCHA
                    sitekey={constants.RECAPTCHA_SITE_KEY}
                    onChange={setCaptcha}
                />
            </div>
            <button type="submit" className="btn btn-primary" disabled={isDisabled()}>
                {intl.formatMessage({ id: 'contactForm.submit' })}
            </button>

            <Alert
                color="primary"
                className="mt-3"
                isOpen={alert === 'success'}
                toggle={() => setAlert('hidden')}
            >
                <p>{intl.formatMessage({ id: 'contactForm.alert.success' })}</p>
            </Alert>

            <Alert
                color="warning"
                className="mt-3"
                isOpen={alert === 'error'}
                toggle={() => setAlert('hidden')}
            >
                <p>{intl.formatMessage({ id: 'contactForm.alert.error' })}</p>
            </Alert>
        </form>
    );
};

ContactForm.propTypes = {
    // context: PropTypes.shape({
    //     intl: PropTypes.object.isRequired,
    // }).isRequired,
};

export default ContactForm;
