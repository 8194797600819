import teamList from '../sources/team';

export const FETCH_TEAM = 'FETCH_TEAM';

export function fetchTeam() {
	return {
		type: FETCH_TEAM,
		payload: teamList
	};
}
