import { FETCH_ARTICLES, FETCH_ARTICLE_CONTENT } from '../types/articles';

const INITIAL_STATE = { articleList: [], articleContent: null };

const articlesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_ARTICLES:
			return { ...state, articleList: action.payload };
		case FETCH_ARTICLE_CONTENT:
			return { ...state, articleContent: action.payload };
		default:
			return state;
	}
};

export default articlesReducer;
