import { paths } from '../sources/constant';

const navList = [{
	id: 'services',
	title: 'nav.services',
	subLinks: [{
		title: 'nav.productDiscovery',
		to: paths.PRODUCT_DISCOVERY
	}, {
		title: 'nav.softwareDelivery',
		to: paths.SOFTWARE_DELIVERY
	}, {
		title: 'nav.softwareModernization',
		to: paths.SOFTWARE_MODERNIZATION
	}, {
		title: 'nav.aiTraining',
		to: paths.AI_TRAINING
	}, {
		title: 'nav.agileTraining',
		to: paths.AGILE_TRAINING
	}, {
		title: 'nav.agileTransformation',
		to: paths.AGILE_TRANSFORMATION
	}]
}, {
	id: 'products',
	title: 'nav.products',
	subLinks: [{
		title: 'nav.curriculumSystem',
		to: paths.CURRICULUM_SYSTEM
	}, {
		title: 'nav.coachingSystem',
		to: paths.COACHING_SYSTEM
	}, {
		title: 'nav.membershipSystem',
		to: paths.MEMBERSHIP_SYSTEM
	}, {
		title: 'nav.websiteEngine',
		to: paths.WEBSITE_ENGINE
	}]
}, {
	id: 'articles',
	title: 'nav.articles',
	to: paths.ARTICLES
}, {
	id: 'about',
	title: 'nav.about',
	to: paths.ABOUT
}, {
	id: 'jobs',
	title: 'nav.jobs',
	to: paths.JOBS
},
{
	id: 'terms',
	title: 'nav.terms',
	to: paths.TERMS,
	hide: true
}, {
	id: 'privacy-policy',
	title: 'nav.privacyPolicy',
	to: paths.PRIVACY_POLICY,
	hide: true
},
{
	id: 'contact',
	title: 'nav.contact',
	anchor: true,
	to: 'contactForm'
}
];

export default navList;
