import { configureStore } from '@reduxjs/toolkit';
import promise from 'redux-promise';
import rootReducer from '../reducers';

export default function configureAppStore(initialState) {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(promise),
        preloadedState: initialState,
    });
}
