const testimonialList = [
	// we can leave name and/or organisation empty, and formatting of the testimonials will handle it gracefully
	{
		id: 1,
		name: '',
		position: 'Product Owner',
		organisation: 'New Custom Product',
		content: 'In just 9 weeks, our core product was ready to test. The team worked closely with us and identified what was truly most important.'
	},
	{
		id: 2,
		name: "",
		position: "Participant",
		organisation: "Agile Team Training",
		content: "The session provided a clear understanding of how Scrum operates. It helped correct some misconceptions and was a valuable and beneficial exercise overall."
	}
];

export default testimonialList;
