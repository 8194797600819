import '../styles/Divider.scss';

import React from 'react';
import PropTypes from 'prop-types';

class Divider extends React.PureComponent {
	render() {
		const { id, className } = this.props;

		return (
			<div id={id} className={`divider-container ${className}`}>
				<div className="divider" />
			</div>
		);
	}
}

Divider.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string
};

Divider.defaultProps = {
	id: '',
	className: ''
};

export default Divider;
