import {
	FETCH_PRODUCT_OFFERINGS,
	FETCH_PRODUCT,
	FETCH_PRODUCT_EXPLORE,
	FETCH_PRODUCT_DESCRIPTION
} from '../actions/products';

const INITIAL_STATE = {
	productList: [],
	product: null,
	exploreProductsList: [],
	productDescription: null,
};

const productsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_PRODUCT_OFFERINGS:
			return { ...state, productList: action.payload };
		case FETCH_PRODUCT:
			return { ...state, product: action.payload };
		case FETCH_PRODUCT_EXPLORE:
			return { ...state, exploreProductsList: action.payload };
		case FETCH_PRODUCT_DESCRIPTION:
			return { ...state, productDescription: action.payload };
		default:
			return state;
	}
};

export default productsReducer;
