import { productId } from '../sources/constant';

// import productDiscoveryImage1 from '../assets/images/pict-2-new.jpg';
// import productDiscoveryImage2 from '../assets/images/pict-6.jpg';

// import agileTransformationImage1 from '../assets/images/scrum-process.jpg';

// import businessValidationImage1 from '../assets/images/design-thinking-mobile.png';

/*
	{@format} - localisation
	{@slider} - convert to carousel
				use '$' to seperate between slides
*/
const productDescriptionList = [{
	id: productId.CURRICULUM_SYSTEM,
	intro: {
		title: 'productDescription.curriculumSystem.intro.title',
		text: 'productDescription.curriculumSystem.intro.text'
	},
    // We keep old service description as placeholder first: these to update to create actual product page later
	content: [{
		html: `<div>
				<section>
					<h6 class="service-title">{@format: productDescription.curriculumSystem.content.section1.title}</h6>
					<p>{@format: productDescription.curriculumSystem.content.section1.text1}</p>
					<h3 class="service-quotes">
					{@format: productDescription.curriculumSystem.content.section1.quote}
					<span class="small d-block"><small>{@format: productDescription.curriculumSystem.content.section1.quoter}</small></span>
					</h3>
				</section>
				<section>
					<h6 class="service-title">{@format: productDescription.curriculumSystem.content.section2.title}</h6>
					<p>{@format: productDescription.curriculumSystem.content.section2.text1}</p>
					<ul>
					<li>{@format: productDescription.curriculumSystem.content.section2.benefit1}</li>
					<li>{@format: productDescription.curriculumSystem.content.section2.benefit2}</li>
					<li>{@format: productDescription.curriculumSystem.content.section2.benefit3}</li>
					<li>{@format: productDescription.curriculumSystem.content.section2.benefit4}</li>
					<li>{@format: productDescription.curriculumSystem.content.section2.benefit5}</li>
					</ul>
				</section>
				<section>
					<h6 class="service-title">{@format: productDescription.curriculumSystem.content.section3.title}</h6>
					<p>{@format: productDescription.curriculumSystem.content.section3.text1}</p>
					<ul>
					<li>{@format: productDescription.curriculumSystem.content.section3.feature1}</li>
					<li>{@format: productDescription.curriculumSystem.content.section3.feature2}</li>
					<li>{@format: productDescription.curriculumSystem.content.section3.feature3}</li>
					<li>{@format: productDescription.curriculumSystem.content.section3.feature4}</li>
					<li>{@format: productDescription.curriculumSystem.content.section3.feature5}</li>
					<li>{@format: productDescription.curriculumSystem.content.section3.feature6}</li>
					</ul>
				</section>
				<section class="service-pointers">
					<h6 class="service-title">{@format: productDescription.curriculumSystem.content.section4.title}</h6>
					<p>{@format: productDescription.curriculumSystem.content.section4.text1}</p>
					<p>{@format: productDescription.curriculumSystem.content.section4.text2}</p>
				</section>
				</div>`
  	}]
}, {
	id: productId.COACHING_SYSTEM,
	intro: {
		title: 'productDescription.coachingSystem.intro.title',
		text: 'productDescription.coachingSystem.intro.text'
	},
	content: [{
		html: `<div>
				  <!-- The Hidden Challenges of Coaching Today -->
				  <section>
					<h6 class="service-title">{@format: productDescription.coachingSystem.content.section1.title}</h6>
					<p>{@format: productDescription.coachingSystem.content.section1.text1}</p>
					<ul>
					  <li>{@format: productDescription.coachingSystem.content.section1.bullet1}</li>
					  <li>{@format: productDescription.coachingSystem.content.section1.bullet2}</li>
					  <li>{@format: productDescription.coachingSystem.content.section1.bullet3}</li>
					  <li>{@format: productDescription.coachingSystem.content.section1.bullet4}</li>
					  <li>{@format: productDescription.coachingSystem.content.section1.bullet5}</li>
					</ul>
					<!-- Image Placeholder: Illustration of typical coaching challenges (manual note-taking, scattered tools) -->
				  </section>
	
					<!-- One Platform to Rule Them All -->
					<section>
						<h6 class="service-title">{@format: productDescription.coachingSystem.content.section2.title}</h6>
						<p>{@format: productDescription.coachingSystem.content.section2.text1}</p>
						<p>{@format: productDescription.coachingSystem.content.section2.text2_part1}<span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section2.highlightPlatform}</span>{@format: productDescription.coachingSystem.content.section2.text2_part2}<span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section2.highlightSinglePlatform}</span>{@format: productDescription.coachingSystem.content.section2.text2_part3}<span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section2.highlightSnowflake}</span>{@format: productDescription.coachingSystem.content.section2.text2_part4}</p>
						<!-- Image Placeholder: Illustration of the Snowflake platform's unified dashboard with all tools integrated -->
					</section>
	
				  <!-- AI That Does More Than Assist—It Elevates -->
				  <section>
					<h6 class="service-title">{@format: productDescription.coachingSystem.content.section3.title}</h6>
					<p>{@format: productDescription.coachingSystem.content.section3.text1}</p>
					<p><span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section3.highlightBefore}</span> {@format: productDescription.coachingSystem.content.section3.beforeText}</p>
					<p><span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section3.highlightDuring}</span> {@format: productDescription.coachingSystem.content.section3.duringText}</p>
					<p><span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section3.highlightAfter}</span> {@format: productDescription.coachingSystem.content.section3.afterText}</p>
					<!-- Image Placeholder: Example of AI suggesting goals before, during, and after sessions -->
				  </section>
 
				  <!-- Empowering Coaches and Organizations Alike -->
					<section>
						<h6 class="service-title">{@format: productDescription.coachingSystem.content.section4.title}</h6>
						<p><span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section4.highlightSnowflake}</span> was built with two key groups in mind: <span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section4.highlightCoaches}</span> who need flexibility and control, and <span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section4.highlightOrganizations}</span> that need to oversee multiple coaching engagements without losing sight of privacy and confidentiality.</p>
						<p>{@format: productDescription.coachingSystem.content.section4.text2}</p>
						<!-- Quote Section -->
						<section class="quote-section" style="text-align: center;">
							<blockquote class="quote-text" style="font-style: italic; font-size: 1.5rem;">{@format: productDescription.coachingSystem.content.section4.quoteText}</blockquote>
							<span class="quote-author" style="font-size: 1rem;">{@format: productDescription.coachingSystem.content.section4.quoteAuthor}</span>
						</section>
						<!-- Image Placeholder: Illustration showing privacy controls and organization-wide oversight -->
					</section>
	
					<!-- Data-Driven Coaching That Doesn’t Sacrifice Intuition -->
					<section>
						<h6 class="service-title">{@format: productDescription.coachingSystem.content.section5.title}</h6>
						<p>{@format: productDescription.coachingSystem.content.section5.text1}</p>
						<p>{@format: productDescription.coachingSystem.content.section5.text2_part1}<span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section5.highlightProgressTracking}</span>{@format: productDescription.coachingSystem.content.section5.text2_part2}</p>
						<p>{@format: productDescription.coachingSystem.content.section5.text3}</p>
						<!-- Image Placeholder: Data-driven dashboard showing visual tracking of client progress -->
					</section>
	
					<!-- The Secret to Streamlined Coaching for Teams -->
					<section>
						<h6 class="service-title">{@format: productDescription.coachingSystem.content.section6.title}</h6>
						<p>{@format: productDescription.coachingSystem.content.section6.text1}</p>
						<p>{@format: productDescription.coachingSystem.content.section6.text2_part1}<span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section6.highlightManagementFeatures}</span>{@format: productDescription.coachingSystem.content.section6.text2_part2}</p>
						<p>{@format: productDescription.coachingSystem.content.section6.text3}</p>
						<!-- Image Placeholder: Team management interface tracking multiple coaching sessions and results -->
					</section>
	
					<!-- Built by Practitioners, for Practitioners -->
					<section>
						<h6 class="service-title">{@format: productDescription.coachingSystem.content.section7.title}</h6>
						<p>{@format: productDescription.coachingSystem.content.section7.text1}</p>
						<p>{@format: productDescription.coachingSystem.content.section7.text2}</p>
						<p>{@format: productDescription.coachingSystem.content.section7.text3}</p>
						<!-- Image Placeholder: Practitioners working with the Snowflake system, using its tools in real-world settings -->
					</section>
					<!-- Ready to Take Your Coaching to the Next Level? -->
					<section>
						<h6 class="service-title">{@format: productDescription.coachingSystem.content.section8.title}</h6>
						<p>
							{@format: productDescription.coachingSystem.content.section8.text1.part1}
							<span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section8.highlightSnowflake}</span>
							{@format: productDescription.coachingSystem.content.section8.text1.part2}
						</p>
						<p><span class="text-bolder brown">{@format: productDescription.coachingSystem.content.section8.highlightRevolutionize}</span></p>
						<p>{@format: productDescription.coachingSystem.content.section8.text3}</p>
					</section>
				</div>`
	  }]
}, {
	id: productId.MEMBERSHIP_SYSTEM,
	intro: {
		title: 'productDescription.membershipSystem.intro.title',
		text: 'productDescription.membershipSystem.intro.text'
	},
	"content": [{
		"html": `<div>
				  <section>
					<h6 class="service-title">{@format: productDescription.membershipSystem.content.section1.title}</h6>
					<p>{@format: productDescription.membershipSystem.content.section1.text}</p>
					<h3 class="service-quotes">
					  {@format: productDescription.membershipSystem.content.section1.quote}
					  <span class="small d-block"><small>{@format: productDescription.membershipSystem.content.section1.quoter}</small></span>
					</h3>
				  </section>
				  <section>
					<h6 class="service-title">{@format: productDescription.membershipSystem.content.section2.title}</h6>
					<p>{@format: productDescription.membershipSystem.content.section2.text1}</p>
					<ul>
					  <li>{@format: productDescription.membershipSystem.content.section2.benefit1}</li>
					  <li>{@format: productDescription.membershipSystem.content.section2.benefit2}</li>
					  <li>{@format: productDescription.membershipSystem.content.section2.benefit3}</li>
					  <li>{@format: productDescription.membershipSystem.content.section2.benefit4}</li>
					  <li>{@format: productDescription.membershipSystem.content.section2.benefit5}</li>
					  <li>{@format: productDescription.membershipSystem.content.section2.benefit6}</li>
					</ul>
				  </section>
				  <section>
					<h6 class="service-title">{@format: productDescription.membershipSystem.content.section3.title}</h6>
					<p>{@format: productDescription.membershipSystem.content.section3.text1}</p>
					<p>{@format: productDescription.membershipSystem.content.section3.text2}</p>
					<p>{@format: productDescription.membershipSystem.content.section3.text3}</p>
					<p>{@format: productDescription.membershipSystem.content.section3.text4}</p>
					<p>{@format: productDescription.membershipSystem.content.section3.text5}</p>
				  </section>
				  <section class="service-pointers">
					<h6 class="service-title">{@format: productDescription.membershipSystem.content.section4.title}</h6>
					<ul>
					  <li>{@format: productDescription.membershipSystem.content.section4.takeaway1}</li>
					  <li>{@format: productDescription.membershipSystem.content.section4.takeaway2}</li>
					  <li>{@format: productDescription.membershipSystem.content.section4.takeaway3}</li>
					  <li>{@format: productDescription.membershipSystem.content.section4.takeaway4}</li>
					</ul>
				  </section>
				</div>`
	  }],
}, {
	id: productId.WEBSITE_ENGINE,
	intro: {
		title: 'productDescription.websiteEngine.intro.title',
		text: 'productDescription.websiteEngine.intro.text'
	},
	content: [{
		html: `<div>
				  <section>
					<h6 class="service-title">{@format: productDescription.websiteEngine.content.section1.title}</h6>
					<p>{@format: productDescription.websiteEngine.content.section1.text}</p>
					<h3 class="service-quotes">
					  {@format: productDescription.websiteEngine.content.section1.quote}
					  <span class="small d-block"><small>{@format: productDescription.websiteEngine.content.section1.quoter}</small></span>
					</h3>
				  </section>
				  <section>
					<h6 class="service-title">{@format: productDescription.websiteEngine.content.section2.title}</h6>
					<p>{@format: productDescription.websiteEngine.content.section2.text1}</p>
					<ul>
					  <li>{@format: productDescription.websiteEngine.content.section2.benefit1}</li>
					  <li>{@format: productDescription.websiteEngine.content.section2.benefit2}</li>
					  <li>{@format: productDescription.websiteEngine.content.section2.benefit3}</li>
					  <li>{@format: productDescription.websiteEngine.content.section2.benefit4}</li>
					  <li>{@format: productDescription.websiteEngine.content.section2.benefit5}</li>
					</ul>
				  </section>
				  <section>
						<h6 class="service-title">{@format: productDescription.websiteEngine.content.section3.title}</h6>
						<p>{@format: productDescription.websiteEngine.content.section3.text1}</p>
						<ul>
							<li>{@format: productDescription.websiteEngine.content.section3.feature1}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature2}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature3}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature4}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature5}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature6}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature7}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature8}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature9}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature10}</li>
							<li>{@format: productDescription.websiteEngine.content.section3.feature11}</li>
						</ul>
						<p>{@format: productDescription.websiteEngine.content.section3.text2}</p>
				  </section>
				  <section class="service-pointers">
					<h6 class="service-title">{@format: productDescription.websiteEngine.content.section4.title}</h6>
					<ul>
					  <li>{@format: productDescription.websiteEngine.content.section4.takeaway1}</li>
					  <li>{@format: productDescription.websiteEngine.content.section4.takeaway2}</li>
					  <li>{@format: productDescription.websiteEngine.content.section4.takeaway3}</li>
					  <li>{@format: productDescription.websiteEngine.content.section4.takeaway4}</li>
					</ul>
				  </section>
				</div>`
	  }]
}];

export default productDescriptionList;
