import '../styles/ArticleCard.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

class ArticleCardComponent extends React.PureComponent {
	render() {
		const { content } = this.props;

		return (
			<div className="article-card">
				<Link to={content.href}>
					<div className="article-card-img">
						<img src={content.image} alt={content.imageAlt} />
					</div>
					<div className="article-content px-2 pb-2">
						<h6 className="orange article-card-title">
							{content.thumbnailTitle}
						</h6>
						<p className="black article-card-desc">
							{content.thumbnailDescription}
						</p>
						<div className="article-card-author">
							<div className="author-image">
								<img src={content.authorImage} alt={content.author} />
							</div>
							<div className="author-desc">
								<p className="author-name small text-bold brown">{content.author}</p>
								<p className="small black">{moment(content.date, 'DD MMM YYYY').format('DD MMM YYYY')}</p>
							</div>
						</div>
					</div>
				</Link>
			</div>
		);
	}
}

ArticleCardComponent.propTypes = {
	content: PropTypes.shape({
		id: PropTypes.string,
		image: PropTypes.string,
		imageAlt: PropTypes.string,
		thumbnailTitle: PropTypes.string,
		thumbnailDescription: PropTypes.string,
		author: PropTypes.string,
		authorImage: PropTypes.string,
		date: PropTypes.string,
		href: PropTypes.string
	}).isRequired
};

export default ArticleCardComponent;
