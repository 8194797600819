import '../styles/AboutContent.scss';

import React from 'react';
// import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// import image1 from '../assets/images/transform-build-product.png';
import image1 from "../assets/images/poster-joe_chan2_tiny.jpg";

function QRCoachingEvent2024ContentComponent() {
	return (
		<div className="about-container intro-overlap col-12 col-lg-9 mx-auto">
			<div className="intro-content">
				<div className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="QRCoachingEvent2024.intro.title"
						/>
					</h4>
				</div>

				<section className="col-md-11 mx-auto">
					<p>
						<FormattedMessage
							id="QRCoachingEvent2024.story.text1"
						/>
					</p>
                    <p>
						<FormattedMessage
							id="QRCoachingEvent2024.story.text2"
						/>
					</p>
                </section>

                {/* image placeholder 
				<div className="about-image row">
					<div className="col-11 col-md-6 text-center">
						<img src={image1} alt="From transformation to first version" />
					</div>
				</div>
                */
				<div className="about-image row">
					<div className="col-11 col-md-6 text-center">
						<img src={image1} alt="From transformation to first version" />
					</div>
				</div>
				}

                <section className="col-md-11 mx-auto">
					<h4 className="page-title">
						<FormattedMessage
							id="QRCoachingEvent2024.story2.title"
						/>
					</h4>
					<p>
						<FormattedMessage
							id="QRCoachingEvent2024.story2.text1"
						/>
					</p>
					<p>
						<FormattedMessage
							id="QRCoachingEvent2024.story2.text2"
						/>
					</p>
					<p>
						<FormattedMessage
							id="QRCoachingEvent2024.story2.text3"
						/>
					</p>
				</section>
			</div>
		</div>
	);
}

QRCoachingEvent2024ContentComponent.propTypes = {
};

export default QRCoachingEvent2024ContentComponent;
