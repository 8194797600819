import '../styles/JobPositions.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import HtmlTransform from '../components/HtmlTransform';

class JobPositionsComponent extends React.PureComponent {
	render() {
		const { list } = this.props;
		return (
			<div className="job-positions">
				<div className="col-md-7 mx-auto">
					<h4 className="page-title hide">
						<FormattedMessage
							id="jobPositions.title"
						/>
					</h4>

					<div className="job-list">
						{list && list.length > 0
							&& list.map(it => <HtmlTransform key={it.id} html={it.content} />)}
						{(!list || list.length === 0)
							&& (
								<FormattedMessage
									id="jobPositions.notAvailable"
								/>
							)
						}
					</div>
				</div>
			</div>
		);
	}
}

JobPositionsComponent.propTypes = {
	list: PropTypes.arrayOf(PropTypes.object)
};

JobPositionsComponent.defaultProps = {
	list: null
};

export default JobPositionsComponent;
