import approachList from '../sources/approaches';

export const FETCH_APPROACHES = 'FETCH_APPROACHES';

export function fetchApproaches() {
	return {
		type: FETCH_APPROACHES,
		payload: approachList
	};
}
