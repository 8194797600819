import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ServiceOfferings from '../components/ServiceOfferings';

class ServiceExploreComponent extends React.PureComponent {
	render() {
		const { exploreServicesList } = this.props;
		return (
			<div className="more-services-wrapper">
				<h4 className="page-title col-md-8 mx-auto">
					<FormattedMessage
						id="serviceExplore.title"
					/>
				</h4>

				{/* Explore more services */}
				<ServiceOfferings serviceList={exploreServicesList} />
			</div>
		);
	}
}

ServiceExploreComponent.propTypes = {
	exploreServicesList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ServiceExploreComponent;
