const midLevelConsultantJob = {
	id: 3,
	content: [{
		html: ` <h6 class="jobs-title hide"><span class="brown">{@format: jobs.midLevelConsultant.position}</span></h6>
        <p class="jobs-desc">{@format: jobs.midLevelConsultant.desc1}</p>
        <ul class="jobs-item-wrapper">
			<li>{@format: jobs.midLevelConsultant.requirements.point1}</li>
            <li>{@format: jobs.midLevelConsultant.requirements.point2}</li>
            <li>{@format: jobs.midLevelConsultant.requirements.point3}</li>
			<li>{@format: jobs.midLevelConsultant.requirements.point4}</li>
            <li>{@format: jobs.midLevelConsultant.requirements.point5}</li>
        </ul> `
	}]
};

export default midLevelConsultantJob;
