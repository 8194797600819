import '../styles/Card.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

class CardComponent extends React.PureComponent {
	render() {
		const { list, parent, className } = this.props;

		const style = {
			backgroundImage: list.image ? `url(${list.image})` : 'none'
		};
		const link = list.link
			? (
				<Link to={`${parent}/${list.id}`} className="cardlink">
					<FormattedMessage
						id={list.link.text}
					/>
				</Link>
			) : null;
		return (
			<div className={`card-container ${className}`}>
				<div className="card-background" style={style} title={list.imageAlt}>
					<div className="card-content">
						<h4 className="card-title header-regular">
							<span className="brown">
								<FormattedMessage
									id={list.title.first}
								/>
							</span>
							{' '}
							<FormattedMessage
								id={list.title.text}
							/>
						</h4>
						<p className="card-content-desc">
							<FormattedMessage
								id={list.description}
							/>
							{link}
						</p>
					</div>
				</div>
			</div>
		);
	}
}

CardComponent.propTypes = {
	list: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.shape({
			first: PropTypes.string,
			text: PropTypes.string
		}).isRequired,
		description: PropTypes.string,
		image: PropTypes.string,
		imageAlt: PropTypes.string,
		link: PropTypes.shape({
			text: PropTypes.string
		})
	}).isRequired,
	parent: PropTypes.string,
	className: PropTypes.string
};

CardComponent.defaultProps = {
	parent: '',
	className: ''
};

export default CardComponent;
