/* eslint-disable */
import React from 'react';

class Terms extends React.PureComponent {
	render() {
		return (
			<div className="col-12 col-lg-9 mx-auto mt-3">
				<div className="intro-content">
					<div className="col-md-11 mx-auto">
						<h4 className="page-title">Outreach App Terms and Conditions</h4>
						<ol>
							<li>Introduction</li>
							<li>Using our service</li>
							<li>Payments and Cancellations</li> <li>User guidelines</li>
							<li>Customer support</li>
						</ol>
						<h4 className="page-title">1. Introduction</h4>

						<p>
							Hello, and welcome to Outreach App Terms and Conditions of Use.  Thank
							you for using our application. We provide the hosting of personalised
							video and audio content for individuals and companies. By signing up and
							using any of our services, you are entering into an agreement with DTAL
							Technologies, Singapore.
						</p>
						<p>
							Occasionally, we may make changes to the Agreements. When there are
							material changes, we will inform you through the application and send
							you an email. We will notify you in advance and your continued use of
							the Service after the changes have been made will constitute your
							acceptance of the changes. If you do not wish to continue using the
							service under the new version of the Agreements, you may terminate your
							account.
						</p>

						<h4 className="page-title">2. Using our Service</h4>

						<p>
							You can find a description of our Service options on our website, and we
							will explain which Service options are available to you when you create
							an account. There are free audio and video contents when you create an
							Outreach account. These audios and videos do not require payment. Other
							options require payment before you can access (Paid Subscriptions). We
							may also offer special promotional plans from time to time. We reserve
							the right to modify, terminate or amend our offered subscription plan
							and promotional offerings at any time in according to these terms.
						</p>

						<h4 className="page-title">3. Payments and Cancellations</h4>
						<p>
							<b>Payments, Renewal and Cancellations</b>
						</p>
						<p>
							You may purchase an hosting plan from the Outreach App which gives you
							access to Outreach content for a specific time period (“Pre-Paid
							Period”).
						</p>
						<p>
							Your payment to Outreach will automatically renew at the end of the
							applicable subscription period unless you cancel your Paid Subscription
							before the end of the then-current subscription period.  This
							cancellation will take effect the day after the last day of the current
							subscription period, and you will no longer be able to access the
							content. We do not provide refunds or credits for any partial
							subscription periods.
						</p>
						<p>
							For customer support with account-related and payment-related questions
							(“Customer Support Queries”), please contact us at
							contact@dtaltechnologies.com.
						</p>

						<h4 className="page-title">4. User Guidelines</h4>
						<ol>
							<li>
								Copying, redistributing, reproducing, “ripping,” recording,
								transferring, performing or displaying to the public, broadcasting,
								or making available to the public any part of our content, or
								otherwise making any use of the content which is not expressly
								permitted under the Agreements or applicable law or which otherwise
								infringes the intellectual property rights.
							</li>
							<li>
								Using our service to import or copy any local files that you do not
								have the legal right to import or copy in this way;
							</li>
							<li>
								Transferring copies of cached Content from an authorised Device to
								any other Device via any means;
							</li>
							<li>
								Reverse-engineering, decompiling, disassembling, modifying, or
								creating derivative works of our content or any part thereof except
								to the extent permitted by applicable law;
							</li>
							<li>
								Circumventing any tehnology used by Outreach, its licensors, or any
								third party to protect the Content or the Service;
							</li>
							<li>
								Selling, renting, sublicensing or leasing of any part of our
								services and content;
							</li>
							<li>
								Circumventing any territorial restrictions applied by Outreach or it
								licensors;
							</li>{' '}
							<li>
								Removing or altering any copyright, trademark, or other intellectual
								property notices contained on the Content or the Service.
							</li>
							<li>
								Providing your password to any other person or using any other
								person’s username and password;
							</li>
							<li>
								Your password protects your user account, and you are solely
								responsible for keeping your password confidential and secure. You
								understand that you are responsible for all use (including any
								unauthorized use) of your username and password on the Service. If
								your username or password is lost or stolen, or if you believe there
								has been unauthorized access to your account by a third party, you
								must notify us immediately and change your password as soon as
								possible.
							</li>
						</ol>

						<h4 className="page-title">5. Customer support</h4>

						<p>
							For customer support with account-related and payment-related questions
							(“Customer Support Queries”), please contact us at
							contact@dtaltechnologies.com.
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default Terms;
