import { articleId, paths } from '../../sources/constant';

import coverImage from '../../assets/images/snowflakeCoaching/joe-discuss-1-hero.jpg';
import joeProfileImage from '../../assets/images/joe-thumbnail.jpg';
import coachImage from '../../assets/images/snowflakeCoaching/snowflakeCoaching-3.jpg';
import aiInsightsImage from '../../assets/images/snowflakeCoaching/joe-coach-1.jpg';
import coachingPlatformImage from '../../assets/images/snowflakeCoaching/joe-coach-2.jpg';

const snowflakeCoachingArticle = {
  id: articleId.SNOWFLAKE_COACHING,
  image: coverImage,
  imageAlt: 'Snowflake Coaching System',
  author: 'Algebra with Dtal Technologies',
  authorImage: joeProfileImage,
  date: '11 Sep 2024',
  href: paths.SNOWFLAKE_COACHING,
  en: {
    title: {
      first: '',
      text: 'Empowering Coaches with AI: The Story Behind the Snowflake Coaching System',
      translate: false
    },
    thumbnailTitle: 'Empowering Coaches with AI',
    thumbnailDescription: 'Discover how a conversation between experienced practitioners sparked the creation of an AI-powered coaching platform designed to revolutionize the way private coaches work.',
    content: [{
      html: `
        <h4 class="page-title">The Evolution of the Snowflake Coaching System: From Concept to Reality</h4>
        <p>Learn how the Snowflake Coaching System evolved through a collaborative effort between Joe, Esther, and Antti to provide a powerful tool for private practitioners, leveraging the power of AI to streamline coaching processes.</p>
    
        <h5 class="subtitle">Introduction: Recognizing the Need for Change</h5>
        <p>Joe has long been passionate about empowering practitioners working in private coaching. When he encountered the work of Esther and Antti, who had developed highly customized software platforms for complex needs, he realized there was an opportunity to bring this innovation to the world of coaching.</p>
        <h4 style="text-align: center; font-style: italic;">
          "Practitioners often work independently, relying on their own expertise and resources. But what if they had a system that lightened the load and helped them focus more on their clients?"
        </h4>
        <p>Joe saw the untapped potential of a system that could support these practitioners, freeing them from the administrative burden and empowering them to deliver better results.</p>
        <p>To learn more about Joe and his work, visit his <a href="https://www.coachjoechan.com" target="_blank" rel="noopener noreferrer">official website</a> or connect with him on <a href="https://www.linkedin.com/in/coachjoechan/" target="_blank" rel="noopener noreferrer">LinkedIn</a>.</p>
    
        <h5 class="subtitle">Identifying the Gap: The Challenges of Working Alone</h5>
        <p>Private practitioners, especially in the coaching space, often find themselves juggling client data, session notes, and follow-ups, all while working solo. Without a supportive infrastructure, they must rely on their own resources and skills to manage their practice.</p>
        <p>Joe, with his extensive experience in working with youth and families, understood these challenges firsthand. He also had a desire to share his knowledge and experience with other practitioners, particularly those just starting out. He envisioned a system that would not only help them manage their work but also serve as a tool for professional growth.</p>
        <h4 style="text-align: center; font-style: italic;">
          “It’s about more than just coaching. It’s about sharing knowledge, supporting new practitioners, and using technology to elevate the profession."
        </h4>
    
        <div class="row img-wrapper">
          <div class="col-12 col-lg-11 mx-auto">
            <p><img src="${coachImage}" alt="Coach" class="img-fluid"/></p>
          </div>
        </div>
    
        <h5 class="subtitle">Co-Creation: A Multi-Directional Process</h5>
        <p>From the outset, it was clear that this project needed to be <strong>co-created</strong>. Joe brought his deep understanding of coaching challenges and the vision of how technology could transform the field. But to bring that vision to life, Joe teamed up with Algebra Technologies—led by Esther and Antti—whose technical and innovative expertise provided the foundation for turning ideas into reality.</p>
        <p>The process was not one-directional. Through collaborative brainstorming, ideas flowed in multiple directions, continuously shaping and reshaping the concept. Esther, Antti, and Joe all contributed insights that built upon each other, making the co-creation possible.</p>
    
        <h5 class="subtitle">The Role of Algebra Technologies: Driving Co-Creation Through Technical Expertise</h5>
        <p>At the heart of this co-creative process was Algebra Technologies. Without the technical expertise, vision, and AI capabilities that Algebra brought, this system would have remained a concept rather than a functioning solution.</p>
        <p>Algebra Technologies was the driving force that introduced the <strong>co-creative model</strong> into the project. With deep expertise in ideation, solution architecture, and technology development, they enabled a collaborative environment where the coaching solution could evolve from multiple perspectives. Every iteration of the product was born out of the interaction between Joe’s real-world coaching experience and Algebra’s technical prowess.</p>
        <h4 style="text-align: center; font-style: italic;">
          “Algebra didn’t just build a tool; they facilitated the entire co-creative process that brought Joe’s vision into reality.”
        </h4>
        
        <p>From the early stages of ideation through to the architectural design and implementation, Algebra’s technical team played a central role in every facet of development. They integrated cutting-edge AI into the solution and ensured it worked seamlessly with the existing base platform, which added complexity but also propelled the organizational model forward.</p>
        <p>Algebra's ability to ideate and architect a solution from scratch, while ensuring it worked alongside existing systems, was critical to overcoming technical challenges. Their AI expertise made it possible to build a platform that actively supports coaches in real time, enhancing their sessions and engagement with clients.</p>
    
        <h5 class="subtitle">Bringing AI to Life: Enhancing the Coaching Experience</h5>
        <p>What truly set the Snowflake Coaching System apart was the use of <strong>generative AI</strong> integrated into the coaching platform. Algebra Technologies, in collaboration with Joe, found ways to harness AI to assist coaches at every step of their journey.</p>
        <p>The platform wasn’t just designed to automate tasks; it was built to <strong>augment</strong> the coaching process. Coaches receive AI-generated insights before sessions, personalized plans during sessions, and meaningful follow-ups afterward. These tools enable coaches to work more efficiently, focusing their energy on the client relationship rather than on repetitive administrative work.</p>
        <h4 style="text-align: center; font-style: italic;">
          "By incorporating AI, we’ve turned coaching into a data-driven, insightful process—while still leaving room for the practitioner’s intuition to shine."
        </h4>
        
        <p>The solution allowed practitioners to stay centered on what they do best: guiding their clients, with AI seamlessly handling background operations.</p>
    
        <div class="row img-wrapper">
          <div class="col-12 col-lg-11 mx-auto">
            <p><img src="${aiInsightsImage}" alt="AI Insights" class="img-fluid"/></p>
          </div>
        </div>
    
        <h5 class="subtitle">Solving Both Operational and Professional Needs</h5>
        <p>This platform was co-created to meet two key objectives. First, it solved the <strong>operational needs</strong> of coaches by automating and streamlining processes such as session notes, follow-ups, and client data management. Second, it addressed their <strong>professional needs</strong>, empowering them with real-time insights and AI-driven support to enhance their coaching practice.</p>
        <p>Through co-creation, Algebra and Joe built a system that was as much about professional growth as it was about operational efficiency. Private practitioners no longer need to rely solely on their own resources—they now have a tool that supports their work from start to finish.</p>
    
        <div class="row img-wrapper">
          <div class="col-12 col-lg-11 mx-auto">
            <p><img src="${coachingPlatformImage}" alt="Coaching Platform" class="img-fluid"/></p>
          </div>
        </div>
    
        <h5 class="subtitle">Built for Coaches, by Coaches</h5>
        <p>What truly makes the Snowflake Coaching System unique is that it wasn’t just created by developers or technologists—it was designed with deep input from practitioners themselves.</p>
    
        <h5 class="subtitle">The Future: Expanding Reach and Impact</h5>
        <p>Joe’s vision for the future of the Snowflake Coaching System doesn’t stop with today’s capabilities.</p>
        <p>The system is already making a difference in how practitioners manage their work, but Joe imagines a future where AI can help coaches expand their practice even further.</p>
        <p>With AI tools at their disposal, practitioners can:</p>
        <ul>
          <li>Provide basic support to clients even when they are unavailable.</li>
          <li>Extend their reach to more clients while maintaining the quality of coaching.</li>
          <li>Free up time for personal growth and business development, allowing practitioners to focus on honing their skills while AI handles the heavy lifting.</li>
        </ul>
    
        <h5 class="subtitle">Conclusion: Are You Ready to Revolutionize Your Coaching?</h5>
        <p>The Snowflake Coaching System is more than just a tool—it’s a platform built by practitioners for practitioners, designed to empower you to focus on what truly matters: helping your clients grow.</p>
        <p>Whether you're an experienced coach or just starting out, Snowflake provides the AI-powered insights and streamlined processes you need to succeed.</p>
        <p>Are you ready to take your coaching practice to the next level?</p>
        <p>Contact us today to learn how Snowflake can help you revolutionize your coaching practice.</p>
      `
    }]    
  }
};

export default snowflakeCoachingArticle;
