import { connect } from 'react-redux';
import { fetchJobs } from '../actions/jobs';
import Jobs from '../pages/Jobs';

const mapStateToProps = state => ({
	jobList: state.jobs.jobList
});

const mapDispatchToProps = dispatch => ({
	fetchJobs: () => {
		dispatch(fetchJobs());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
