import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ServiceOfferings from '../components/ServiceOfferings';

class ServicesComponent extends React.PureComponent {
	render() {
		const { serviceList } = this.props;

		return (
			<div id="services" className="services">
				<div className="col-md-8 mx-auto">
					<h4 className="services-title page-title">
						<FormattedMessage
							id="services.title"
						/>
					</h4>
					<p className="text-bigger black">
						<FormattedMessage
							id="services.description"
						/>
					</p>
				</div>

				{/* Service Offerings */}
				<ServiceOfferings serviceList={serviceList} />
			</div>
		);
	}
}

ServicesComponent.propTypes = {
	serviceList: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ServicesComponent;
