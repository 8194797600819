import testimonialList from '../sources/testimonials';

export const FETCH_TESTIMONIALS = 'FETCH_TESTIMONIALS';

export function fetchTestimonials() {
	return {
		type: FETCH_TESTIMONIALS,
		payload: testimonialList
	};
}
