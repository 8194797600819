import _ from 'lodash';
import serviceList from '../sources/serviceOfferings';
import serviceDescriptionList from '../sources/serviceDescriptions';

export const FETCH_SERVICE_OFFERINGS = 'FETCH_SERVICE_OFFERINGS';
export const FETCH_SERVICE = 'FETCH_SERVICE';
export const FETCH_SERVICE_EXPLORE = 'FETCH_SERVICE_EXPLORE';
export const FETCH_SERVICE_DESCRIPTION = 'FETCH_SERVICE_DESCRIPTION';

export function fetchServiceOfferings() {
	return {
		type: FETCH_SERVICE_OFFERINGS,
		payload: serviceList
	};
}

export function fetchService(serviceId) {
	const service = fetchServiceById(serviceList, serviceId);

	return {
		type: FETCH_SERVICE,
		payload: service
	};
}

export function fetchServiceExplore(serviceId, itemsToDisplay) {
	const exploreServicesId = _.map(serviceList, 'id');
	_.remove(exploreServicesId, id => id === serviceId);
	const exploreServicesList = [];
	for (let i = 0; i < itemsToDisplay; i += 1) {
		const randomNumber = exploreServicesId[Math.floor(Math.random() * exploreServicesId.length)];
		const selectedId = _.remove(exploreServicesId, id => id === randomNumber);
		exploreServicesList.push(fetchServiceById(serviceList, selectedId[0]));
	}

	return {
		type: FETCH_SERVICE_EXPLORE,
		payload: exploreServicesList
	};
}

export function fetchServiceDescription(serviceId) {
	const serviceDescription = fetchServiceById(serviceDescriptionList, serviceId);

	return {
		type: FETCH_SERVICE_DESCRIPTION,
		payload: serviceDescription
	};
}

/** */

function fetchServiceById(list, serviceId) {
	return _.find(list, service => service.id === serviceId);
}
