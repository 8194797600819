import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import HeroBanner from '../components/HeroBanner';
import ServiceContent from '../components/ServiceContent';
import ServiceExplore from '../components/ServiceExplore';

import ExtendNavbar from '../components/ExtendNavbar';

const itemsToDisplay = 3;

const ServicePage = ({ 
    fetchService, 
    fetchServiceDescription, 
    fetchServiceExplore, 
    service = null, 
    serviceDescription = null, 
    exploreServicesList = null 
}) => {
    const { serviceId } = useParams();

    useEffect(() => {
        if (serviceId) {
            fetchService(serviceId);
            fetchServiceDescription(serviceId);
            fetchServiceExplore(serviceId, itemsToDisplay);
        }
    }, [serviceId, fetchService, fetchServiceDescription, fetchServiceExplore]);

    return (
        <div className="service">
            {/* Service Hero */}
            <HeroBanner content={service} />

            {/* Trigger Navbar Extension */}
            <ExtendNavbar />

            {/* Service Content */}
            <ServiceContent serviceDescription={serviceDescription} />

            {/* Explore More Services */}
            <ServiceExplore exploreServicesList={exploreServicesList} />
        </div>
    );
};

ServicePage.propTypes = {
    fetchService: PropTypes.func.isRequired,
    fetchServiceDescription: PropTypes.func.isRequired,
    fetchServiceExplore: PropTypes.func.isRequired,
    service: PropTypes.shape(),
    serviceDescription: PropTypes.shape(),
    exploreServicesList: PropTypes.arrayOf(PropTypes.object)
};

export default ServicePage;
