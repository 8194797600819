import '../styles/IntroVideo.scss';

import React from 'react';
// import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// import introMp4 from '../assets/videos/Ideas/MP4/dtal-web.mp4';
// import introMp4 from '../assets/videos/Ideas/MP4/algebra-intro-3.mp4';
import introMp4 from '../assets/videos/Ideas/MP4/AlgebraVideo2-7.mp4';
import introSnapshot from '../assets/videos/Ideas/Snapshots/dtal-web.jpg';

function IntroVideo() {
	return (
		<div className="intro-video-container mx-auto">
			<video autoPlay muted loop playsInline poster={introSnapshot}>
				<source src={introMp4} type="video/mp4" />
				<FormattedMessage
					id="error.videoNotSupported"
				/>
			</video>
			<div className="filter filters" />
			<div className="dot-filter filters" />
		</div>
	);
}

IntroVideo.propTypes = {
};

export default IntroVideo;
