import React, { useContext } from 'react';
import { Waypoint } from 'react-waypoint';
import { NavbarContext } from './AppContainer';

const ExtendNavbarComponent = () => {
    const { extendNavbarBorder } = useContext(NavbarContext);

    return <Waypoint onPositionChange={extendNavbarBorder} />;
};

export default ExtendNavbarComponent;