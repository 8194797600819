import { serviceId, caseId } from '../sources/constant';

import caseStudyImage3 from '../assets/images/case-study-training.jpg';

import productDiscoveryImage1 from '../assets/images/pict-2-new.jpg';
import productDiscoveryImage2 from '../assets/images/pict-6.jpg';

import agileTrainingPhaseImage1 from '../assets/images/agile-training-phase1.png';
import agileTrainingPhaseImage2 from '../assets/images/agile-training-phase2.png';
import agileTrainingPhaseImage3 from '../assets/images/agile-training-phase3.png';
import agileTrainingPhaseImage4 from '../assets/images/agile-training-phase4.png';

import agileTransformationImage1 from '../assets/images/scrum-process.jpg';

/*
	{@format} - localisation
	{@slider} - convert to carousel
				use '$' to seperate between slides
*/
const serviceDescriptionList = [{
	id: serviceId.PRODUCT_DISCOVERY,
	intro: {
		title: 'serviceDescription.productDiscovery.intro.title',
		text: 'serviceDescription.productDiscovery.intro.text'
	},
	content: [{
		html: `<div>
					<h3 class="service-quotes">
						{@format: serviceDescription.productDiscovery.content.quotes}
						<span class="small d-block">
							<small>
								{@format: serviceDescription.productDiscovery.content.quoter}
							</small>
						</span>
					</h3>
					<section>
						<h6 class="service-title">{@format: serviceDescription.productDiscovery.content.title1}</h6>
						<p>
							{@format: serviceDescription.productDiscovery.content.text1}
						</p>
						<p>
							{@format: serviceDescription.productDiscovery.content.text2}
						</p>
						<p>
							{@format: serviceDescription.productDiscovery.content.text3}
						</p>
						<p>
							{@format: serviceDescription.productDiscovery.content.text4}
						</p>
						<p>
							{@format: serviceDescription.productDiscovery.content.text5}
						</p>
						<p>
							{@format: serviceDescription.productDiscovery.content.text6}
						</p>
						<p>
							{@format: serviceDescription.productDiscovery.content.text7}
						</p>
					</section>

					<section>
						<h6 class="service-title">{@format: serviceDescription.productDiscovery.content.title8}</h6>
						<p>
							{@format: serviceDescription.productDiscovery.content.text8}
						</p>
					</section>

					<div class="row service-img-wrapper">
						<div class="col-12 col-md-6">
							<img src=${productDiscoveryImage1} alt="{@format: serviceDescription.productDiscovery.content.imgAlt}"/>
						</div>
						<div class="col-12 col-md-6">
							<img src=${productDiscoveryImage2} alt="{@format: serviceDescription.productDiscovery.content.imgAlt}"/>
						</div>
					</div>

					<section class="service-pointers">
						<ul>
							<li>
								{@format: serviceDescription.productDiscovery.content.point1}
								<span class="sub-point">{@format: serviceDescription.productDiscovery.content.point1a}</span>
							</li>
							<li>
								{@format: serviceDescription.productDiscovery.content.point2}
								<span class="sub-point">{@format: serviceDescription.productDiscovery.content.point2a}</span>
							</li>
							<li>
								{@format: serviceDescription.productDiscovery.content.point3}
								<span class="sub-point">{@format: serviceDescription.productDiscovery.content.point3a}</span>
							</li>
							<li>
								{@format: serviceDescription.productDiscovery.content.point4}
								<span class="sub-point">{@format: serviceDescription.productDiscovery.content.point4a}</span>
							</li>
						</ul>
						<p>
							{@format: serviceDescription.productDiscovery.content.text9}
						</p>
						<p>
							{@format: serviceDescription.productDiscovery.content.text10}
						</p>
					</section>


					<h3 class="service-quotes">
						{@format: serviceDescription.productDiscovery.content.quotes2}
						<span class="small d-block">
							<small>
								{@format: serviceDescription.productDiscovery.content.quoter2}
							</small>
						</span>
					</h3>

					<section>
						<p>
							{@format: serviceDescription.productDiscovery.content.text11}
						</p>
					</section>

					<h3 class="service-quotes">
						{@format: serviceDescription.productDiscovery.content.quotes3}
					</h3>

					<section>
						<h6 class="service-title">{@format: serviceDescription.productDiscovery.content.title12}</h6>
						<p>
							{@format: serviceDescription.productDiscovery.content.text12}
						</p>
						<p>
							{@format: serviceDescription.productDiscovery.content.text13}
						</p>
					</section>
				</div>`
	}]
}, {
	id: serviceId.SOFTWARE_DELIVERY,
	intro: {
		title: 'serviceDescription.softwareDelivery.intro.title',
		text: 'serviceDescription.softwareDelivery.intro.text'
	},
	content: [{
		html: `
				<div>
					<h3 class="service-quotes">
						{@format: serviceDescription.softwareDelivery.content.quotes}
						<span class="small d-block">
							<small>
								{@format: serviceDescription.softwareDelivery.content.quoter}
							</small>
						</span>
					</h3>
				</div>
				<section>
					<h6 class="service-title">{@format: serviceDescription.softwareDelivery.content1.title}</h6>
					<p>
						{@format: serviceDescription.softwareDelivery.content1.text1}
					</p>
				</section>
				<section>
					<h6 class="service-title">{@format: serviceDescription.softwareDelivery.content2.title}</h6>
					<p>
						{@format: serviceDescription.softwareDelivery.content2.text1}
					</p>
					<p>
						{@format: serviceDescription.softwareDelivery.content2.text2}
					</p>
					<p>
						{@format: serviceDescription.softwareDelivery.content2.text3}
					</p>
				</section>
				<section>
					<h6 class="service-title">{@format: serviceDescription.softwareDelivery.content3.title}</h6>
					<p>
						{@format: serviceDescription.softwareDelivery.content3.text1}
						<a href="/services/product-discovery">{@format: serviceDescription.softwareDelivery.content3.link1}</a>
						{@format: serviceDescription.softwareDelivery.content3.text2}
					</p>
				</section>`
	}]
}, {
	id: serviceId.AGILE_TRAINING,
	intro: {
		title: 'serviceDescription.agileTraining.intro.title',
		text: 'serviceDescription.agileTraining.intro.text'
	},
	content: [{
		html: `<div>
					<section>
						<h6 class="service-title">{@format: serviceDescription.agileTraining.content.title1}</h6>
						<p>
							{@format: serviceDescription.agileTraining.content.text1}
							<a href="https://versionone.com/pdf/VersionOne-10th-Annual-State-of-Agile-Report.pdf">{@format: serviceDescription.agileTraining.content.link1}</a>
						</p>
					</section>
					<h3 class="service-quotes">
						{@format: serviceDescription.agileTraining.content.quotes}
						<span class="small d-block">
							<small>
								{@format: serviceDescription.agileTraining.content.quoter}
							</small>
						</span>
					</h3>
					<section>
						<p>
							{@format: serviceDescription.agileTraining.content.text2}
						</p>
					</section>
					<section>
						<p>
							{@format: serviceDescription.agileTraining.content.text3}
						</p>
					</section>
					<section>
						<p>
							{@format: serviceDescription.agileTraining.content.text4}
						</p>
					</section>
					<section>
						<p>
							{@format: serviceDescription.agileTraining.content.text5}
						</p>
					</section>
					{@slider:
						{@format: serviceDescription.agileTraining.phase1.title}: <div class="row">

							<div class="col-8 col-lg-4 mx-auto img-wrapper">
								<img src=${agileTrainingPhaseImage1} alt="{@format: serviceDescription.agileTraining.phase1.title}"/>
							</div>
							<div class="col-12 offset-lg-1 col-lg-7 content-wrapper">
								<ul class="text-left">
									<li>
										{@format: serviceDescription.agileTraining.phase1.point1}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase1.point2}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase1.point3}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase1.point4}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase1.point5}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase1.point6}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase1.point7}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase1.point8}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase1.point9}
									</li>
								</ul>
							</div>
						</div> $
						{@format: serviceDescription.agileTraining.phase2.title}: <div class="row">
							<div class="col-8 col-lg-4 mx-auto img-wrapper">
								<img src=${agileTrainingPhaseImage2} alt="{@format: serviceDescription.agileTraining.phase2.title}"/>
							</div>
							<div class="col-12 offset-lg-1 col-lg-7 content-wrapper">
								<ul class="text-left">
									<li>
										{@format: serviceDescription.agileTraining.phase2.point1}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point2}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point3}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point4}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point5}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point6}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point7}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point8}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point9}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point10}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point11}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase2.point12}
									</li>
								</ul>
							</div>
						</div> $
						{@format: serviceDescription.agileTraining.phase3.title}: <div class="row">
							<div class="col-8 col-lg-4 mx-auto img-wrapper">
								<img src=${agileTrainingPhaseImage3} alt="{@format: serviceDescription.agileTraining.phase3.title}"/>
							</div>
							<div class="col-12 offset-lg-1 col-lg-7 content-wrapper">
								<ul class="text-left">
									<li>
										{@format: serviceDescription.agileTraining.phase3.point1}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase3.point2}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase3.point3}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase3.point4}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase3.point5}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase3.point6}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase3.point7}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase3.point8}
									</li>
								</ul>
							</div>
						</div> $
						{@format: serviceDescription.agileTraining.phase4.title}: <div class="row">
							<div class="col-8 col-lg-4 mx-auto img-wrapper">
								<img src=${agileTrainingPhaseImage4} alt="{@format: serviceDescription.agileTraining.phase4.title}"/>
							</div>
							<div class="col-12 offset-lg-1 col-lg-7 content-wrapper">
								<ul class="text-left">
									<li>
										{@format: serviceDescription.agileTraining.phase4.point1}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase4.point2}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase4.point3}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase4.point4}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase4.point5}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase4.point6}
									</li>
									<li>
										{@format: serviceDescription.agileTraining.phase4.point7}
									</li>
								</ul>
							</div>
						</div> $
					}
					<section class="service-pointers">
						<p>
							{@format: serviceDescription.agileTraining.highlights.header}
						</p>
						<ul>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point1}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point2}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point3}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point4}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point5}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point6}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point7}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point8}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point9}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point10}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point11}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point12}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point13}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point14}
							</li>
							<li>
								{@format: serviceDescription.agileTraining.highlights.pointers.point15}
							</li>
						</ul>
					</section>
					<section>
						<h6 class="service-title">{@format: serviceDescription.agileTraining.content.title6}</h6>
						<p>
							{@format: serviceDescription.agileTraining.content.text6}
						</p>
					</section>
					<section>
						<p>
							<span class="text-bolder brown">{@format: serviceDescription.agileTraining.content.title7}</span>
							{@format: serviceDescription.agileTraining.content.text7}
						</p>
					</section>
					<section>
						<p>
							<span class="text-bolder brown">{@format: serviceDescription.agileTraining.content.title8}</span>
							{@format: serviceDescription.agileTraining.content.text8}
						</p>
					</section>
					<section>
						<p>
							<span class="text-bolder brown">{@format: serviceDescription.agileTraining.content.title9}</span>
							{@format: serviceDescription.agileTraining.content.text9}
						</p>
					</section>
					<section>
						<p>
							<span class="text-bolder brown">{@format: serviceDescription.agileTraining.content.title10}</span>
							{@format: serviceDescription.agileTraining.content.text10}
						</p>
					</section>
				</div>`
	}],
	caseStudy: [{
		id: caseId.GRAB_CASE,
		title: 'Grab Case Study (Coming Soon):',
		desc: 'Equipping the team in Grab with agile mindset',
		image: caseStudyImage3,
		link: '#'
	}]
}, {
	id: serviceId.AGILE_TRANSFORMATION,
	intro: {
		title: 'serviceDescription.agileTransformation.intro.title',
		text: 'serviceDescription.agileTransformation.intro.text'
	},
	content: [{
		html: `<div>
					<p>
					   {@format: serviceDescription.agileTransformation.intro.text2}
					</p>
					<h3 class="service-quotes">
						{@format: serviceDescription.agileTransformation.content.quotes}
						<span class="small d-block">
							<small>
								{@format: serviceDescription.agileTransformation.content.quoter}
							</small>
						</span>
					</h3>
					<section>
						<h6 class="service-title">{@format: serviceDescription.agileTransformation.content1.title1}</h6>
						<p>
							{@format: serviceDescription.agileTransformation.content1.text1}
						</p>
					   <p>
							{@format: serviceDescription.agileTransformation.content1.text2}
						</p>
					</section>
					<section>
						<h6 class="service-title">{@format: serviceDescription.agileTransformation.content2.title1}</h6>
						<p>
							{@format: serviceDescription.agileTransformation.content2.text1}
						</p>
						<p>
							{@format: serviceDescription.agileTransformation.content2.text2}
						</p>
						<p class="service-img-wrapper">
							<img src=${agileTransformationImage1} alt="{@format: serviceDescription.agileTransformation.content1.imgAlt}"/>
						</p>
					</section>
				</div>`
	}]
}, {
	id: serviceId.AI_TRAINING,
	intro: {
		title: 'serviceDescription.aiTraining.intro.title',
		text: 'serviceDescription.aiTraining.intro.text'
	},
	content: [{
		html: `<div>
				<section>
					<h6 class="service-title">{@format: serviceOffering.aiTraining.content.section1.title}</h6>
					<p>{@format: serviceOffering.aiTraining.content.section1.text}</p>
					<h3 class="service-quotes">
					{@format: serviceOffering.aiTraining.content.section1.quote}
					<span class="small d-block"><small>{@format: serviceOffering.aiTraining.content.section1.quoter}</small></span>
					</h3>
				</section>
				<section>
					<h6 class="service-title">{@format: serviceOffering.aiTraining.content.section2.title}</h6>
					<p>{@format: serviceOffering.aiTraining.content.section2.text1}</p>
					<ul>
					<li>{@format: serviceOffering.aiTraining.content.section2.benefit1}</li>
					<li>{@format: serviceOffering.aiTraining.content.section2.benefit2}</li>
					<li>{@format: serviceOffering.aiTraining.content.section2.benefit3}</li>
					<li>{@format: serviceOffering.aiTraining.content.section2.benefit4}</li>
					<li>{@format: serviceOffering.aiTraining.content.section2.benefit5}</li>
					</ul>
				</section>
				<section>
					<h6 class="service-title">{@format: serviceOffering.aiTraining.content.section3.title}</h6>
					<p>{@format: serviceOffering.aiTraining.content.section3.text1}</p>
					<p>{@format: serviceOffering.aiTraining.content.section3.text2}</p>
					<p>{@format: serviceOffering.aiTraining.content.section3.text3}</p>
					<p>{@format: serviceOffering.aiTraining.content.section3.text4}</p>
					<p>{@format: serviceOffering.aiTraining.content.section3.text5}</p>
				</section>
				<section class="service-pointers">
					<h6 class="service-title">{@format: serviceOffering.aiTraining.content.section4.title}</h6>
					<ul>
					<li>{@format: serviceOffering.aiTraining.content.section4.takeaway1}</li>
					<li>{@format: serviceOffering.aiTraining.content.section4.takeaway2}</li>
					<li>{@format: serviceOffering.aiTraining.content.section4.takeaway3}</li>
					<li>{@format: serviceOffering.aiTraining.content.section4.takeaway4}</li>
					</ul>
				</section>
				</div>`
  	}]
}, {
	id: serviceId.SOFTWARE_MODERNIZATION,
	intro: {
		title: 'serviceDescription.softwareModernization.intro.title',
		text: 'serviceDescription.softwareModernization.intro.text'
	},
	"content": [{
    "html": `<div>
              <section>
                <h6 class="service-title">{@format: serviceOffering.softwareModernization.content.section1.title}</h6>
                <p>{@format: serviceOffering.softwareModernization.content.section1.text}</p>
                <h3 class="service-quotes">
                  {@format: serviceOffering.softwareModernization.content.section1.quote}
                  <span class="small d-block"><small>{@format: serviceOffering.softwareModernization.content.section1.quoter}</small></span>
                </h3>
              </section>
              <section>
                <h6 class="service-title">{@format: serviceOffering.softwareModernization.content.section2.title}</h6>
                <p>{@format: serviceOffering.softwareModernization.content.section2.text1}</p>
                <ul>
                  <li>{@format: serviceOffering.softwareModernization.content.section2.benefit1}</li>
                  <li>{@format: serviceOffering.softwareModernization.content.section2.benefit2}</li>
                  <li>{@format: serviceOffering.softwareModernization.content.section2.benefit3}</li>
                  <li>{@format: serviceOffering.softwareModernization.content.section2.benefit4}</li>
                  <li>{@format: serviceOffering.softwareModernization.content.section2.benefit5}</li>
                </ul>
              </section>
              <section>
                <h6 class="service-title">{@format: serviceOffering.softwareModernization.content.section3.title}</h6>
                <p>{@format: serviceOffering.softwareModernization.content.section3.text1}</p>
                <p>{@format: serviceOffering.softwareModernization.content.section3.text2}</p>
                <p>{@format: serviceOffering.softwareModernization.content.section3.text2a}</p>
                <p>{@format: serviceOffering.softwareModernization.content.section3.text2b}</p>
                <p>{@format: serviceOffering.softwareModernization.content.section3.text2c}</p>
                <p>{@format: serviceOffering.softwareModernization.content.section3.text2d}</p>
                <p>{@format: serviceOffering.softwareModernization.content.section3.text3}</p>
              </section>
              <section class="service-pointers">
                <h6 class="service-title">{@format: serviceOffering.softwareModernization.content.section4.title}</h6>
                <ul>
                  <li>{@format: serviceOffering.softwareModernization.content.section4.takeaway1}</li>
                  <li>{@format: serviceOffering.softwareModernization.content.section4.takeaway2}</li>
                  <li>{@format: serviceOffering.softwareModernization.content.section4.takeaway3}</li>
                  <li>{@format: serviceOffering.softwareModernization.content.section4.takeaway4}</li>
                </ul>
              </section>
            </div>`
  }]
}];

export default serviceDescriptionList;
