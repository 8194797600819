import '../styles/ServiceCarouselPhase.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// const pathLength = 0;

class ServiceCarouselPhaseComponent extends React.Component {
	static getClassName(to, i) {
		if (to === i) {
			return 'active';
		}
		if (to > i) {
			return 'done';
		}
		return '';
	}

	constructor(props) {
		super(props);

		const { initialSlide } = props;

		this.state = {
			// from: initialSlide,
			to: initialSlide
		};
	}

	render() {
		const { to } = this.state;
		const { titleArr, goToSlide } = this.props;
		const titleMap = titleArr.map((text, id) => ({ id, text }));

		return (
			<div className="phases mx-auto">
				{titleMap.map((title, i) => (
					<div
						className={`phase-container ${ServiceCarouselPhaseComponent.getClassName(to, i)}`}
						key={title.id}
					>
						{/* {phasePath} */}
						<div
							className="phase"
							onMouseEnter={() => goToSlide(i)}
							onClick={() => goToSlide(i)}
							onKeyDown={() => {}}
							role="button"
							tabIndex={i}
						>
							<div className="phase-content">
								<div className="label">
									<FormattedMessage
										id="servicePhase.phase"
									/>
									{' '}
									{i + 1}
									{' '}
								</div>
								{title.text}
							</div>
						</div>
					</div>
				))}
			</div>
		);
	}
}

ServiceCarouselPhaseComponent.propTypes = {
	initialSlide: PropTypes.number,
	titleArr: PropTypes.arrayOf(PropTypes.string).isRequired,
	goToSlide: PropTypes.func.isRequired
};

ServiceCarouselPhaseComponent.defaultProps = {
	initialSlide: 0
};

export default ServiceCarouselPhaseComponent;
