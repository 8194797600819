import '../styles/Carousel.scss';
import '../styles/ApproachCarousel.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Slider from 'react-slick';
import Banner from '../components/Banner';

class ApproachCarouselComponent extends React.PureComponent {
	render() {
		const settings = {
			dots: true
		};
		const { slides } = this.props;

		return (
			<Banner className="slides">
				<Slider className="carousel mx-auto" {...settings}>
					{slides.map((slide) => {
						const values = {};
						if (slide.title.highlight) {
							Object.keys(slide.title.highlight).map((key) => {
								values[key] = (
									<span className="text-bolditalic brown">
										<FormattedMessage
											id={slide.title.highlight[key]}
										/>
									</span>
								);
								return values;
							});
						}
						return (
							<div className="carousel-content" key={slide.id}>
								<div className="row">
									<h5 className="text-italic text-center col-md-10 col-lg-8 mx-auto">
										<FormattedMessage
											id={slide.title.text}
											values={values}
										/>
									</h5>
								</div>
								<div className="carousel-img row mx-auto">
									<img src={slide.image} alt="" />
								</div>
							</div>
						);
					})}
				</Slider>
			</Banner>
		);
	}
}

ApproachCarouselComponent.propTypes = {
	slides: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		title: PropTypes.shape({
			text: PropTypes.string.isRequired,
			highlight: PropTypes.object
		}),
		image: PropTypes.string.isRequired
	})).isRequired
};

export default ApproachCarouselComponent;
