import _ from 'lodash';
import productList from '../sources/productOfferings';
import productDescriptionList from '../sources/productDescriptions';

export const FETCH_PRODUCT_OFFERINGS = 'FETCH_PRODUCT_OFFERINGS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_EXPLORE = 'FETCH_PRODUCT_EXPLORE';
export const FETCH_PRODUCT_DESCRIPTION = 'FETCH_PRODUCT_DESCRIPTION';

export function fetchProductOfferings() {
	return {
		type: FETCH_PRODUCT_OFFERINGS,
		payload: productList
	};
}

export function fetchProduct(productId) {
	const product = fetchProductById(productList, productId);

	return {
		type: FETCH_PRODUCT,
		payload: product
	};
}

export function fetchProductExplore(productId, itemsToDisplay) {
	const exploreProductsId = _.map(productList, 'id');
	_.remove(exploreProductsId, id => id === productId);
	const exploreProductsList = [];
	for (let i = 0; i < itemsToDisplay; i += 1) {
		const randomNumber = exploreProductsId[Math.floor(Math.random() * exploreProductsId.length)];
		const selectedId = _.remove(exploreProductsId, id => id === randomNumber);
		exploreProductsList.push(fetchProductById(productList, selectedId[0]));
	}

	return {
		type: FETCH_PRODUCT_EXPLORE,
		payload: exploreProductsList
	};
}

export function fetchProductDescription(productId) {
	const productDescription = fetchProductById(productDescriptionList, productId);

	return {
		type: FETCH_PRODUCT_DESCRIPTION,
		payload: productDescription
	};
}

function fetchProductById(list, productId) {
	return _.find(list, product => product.id === productId);
}
