import { serviceId } from '../sources/constant';

import image1 from '../assets/images/new-product-discovery.jpg';
import image2 from '../assets/images/software-delivery.jpg';
import image3 from '../assets/images/agile-training.jpg';
import image4 from '../assets/images/agile-transformation.jpg';
import image5 from '../assets/images/algebra-modernization-tiny.jpg';
import image6 from '../assets/images/algebra-aiTraining-tiny.jpg';
// import image6 from '@/assets/images/design-capability.jpg';

const serviceList = [{
	id: serviceId.PRODUCT_DISCOVERY,
	title: {
		first: 'serviceOffering.product',
		text: 'serviceOffering.discovery'
	},
	description: 'serviceOffering.productDiscoveryDescription',
	image: image1,
	imageAlt: 'Innovation Discovery',
	link: {
		text: 'serviceOffering.productDiscoverylink'
	}
}, {
	id: serviceId.SOFTWARE_DELIVERY,
	title: {
		first: 'serviceOffering.software',
		text: 'serviceOffering.delivery'
	},
	description: 'serviceOffering.softwareDeliveryDescription',
	image: image2,
	imageAlt: 'Software Delivery',
	link: {
		text: 'serviceOffering.softwareDeliverylink'
	}
}, {
	id: serviceId.SOFTWARE_MODERNIZATION,
	title: {
		// first: 'serviceOffering.software',
		// text: 'serviceOffering.modernization'
		first: 'serviceOffering.modernization',
		text: 'serviceOffering.cloudSecurity'
	},
	description: 'serviceOffering.softwareModernizationDescription',
	image: image5,
	imageAlt: 'Software modernization',
	link: {
		text: 'serviceOffering.softwareModernizationlink'
	}
}, {
	id: serviceId.AI_TRAINING,
	title: {
		first: 'serviceOffering.ai',
		text: 'serviceOffering.training'
	},
	description: 'serviceOffering.aiTrainingDescription',
	image: image6,
	imageAlt: 'AI Training',
	link: {
		text: 'serviceOffering.aiTraininglink'
	}
}, {
	id: serviceId.AGILE_TRAINING,
	title: {
		first: 'serviceOffering.agile',
		text: 'serviceOffering.training'
	},
	description: 'serviceOffering.agileTrainingDescription',
	image: image3,
	imageAlt: 'Agile Training',
	link: {
		text: 'serviceOffering.agileTraininglink'
	}
}, {
	id: serviceId.AGILE_TRANSFORMATION,
	title: {
		first: 'serviceOffering.agile',
		text: 'serviceOffering.transformation'
	},
	description: 'serviceOffering.agileTransformationDescription',
	image: image4,
	imageAlt: 'Agile Transformation',
	link: {
		text: 'serviceOffering.agileTransformationlink'
	}
}];

export default serviceList;
