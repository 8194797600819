import '../styles/Nav.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { animateScroll } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Navbar, NavbarBrand, Nav, NavItem
} from 'reactstrap';

import constants from '../constants';
import NavMenu from '../components/NavMenu';

import logo from '../assets/images/logo-full-color.png';

const smallestMenuCount = 0;
const smallerMenuCount = 1;
const smallMenuCount = 2;
const mediumMenuCount = 3;

const NavComponent = ({ 
	fetchNavs, 
	navList = null, 
	extend = false 
}) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const resize = () => {
            if (window.innerWidth <= constants.smallestScreenSize) {
                fetchNavs(smallestMenuCount);
            } else if (window.innerWidth <= constants.smallerScreenSize) {
                fetchNavs(smallerMenuCount);
            } else if (window.innerWidth <= constants.smallScreenSize) {
                fetchNavs(smallMenuCount);
            } else if (window.innerWidth <= constants.mediumScreenSize) {
                fetchNavs(mediumMenuCount);
            } else {
                fetchNavs();
            }
        };

        window.addEventListener('resize', resize);
        resize();

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [fetchNavs]);

    const scrollTop = () => {
        if (location.hash !== '' || location.pathname !== '/') {
            navigate('/');
        }
        animateScroll.scrollToTop();
    };

    return (
        <Navbar
            id="navbar"
            className={`navbar-custom${extend ? ' full-width' : ''}`}
            light
            fixed="top"
        >
            <div className="navbar-container container">
                <NavbarBrand onClick={scrollTop}>
                    <img src={logo} className="__logo" alt="" />
                </NavbarBrand>

                <Nav className="ml-auto navbar-visible" navbar>
                    {navList && navList.map((nav) => {
                        if (nav.hide === true) {
                            return null;
                        }
                        return (
                            <NavItem key={nav.id}>
                                <NavMenu
                                    to={nav.to}
                                    title={nav.title}
                                    subLinks={nav.subLinks}
                                    anchor={nav.anchor}
                                />
                            </NavItem>
                        );
                    })}
                </Nav>
            </div>
            <div className="navbar-border-container container">
                <div className="navbar-border" />
            </div>
        </Navbar>
    );
};

NavComponent.propTypes = {
    fetchNavs: PropTypes.func.isRequired,
    extend: PropTypes.bool,
    navList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            to: PropTypes.string,
            title: PropTypes.string,
            subLinks: PropTypes.arrayOf(PropTypes.object),
            anchor: PropTypes.bool
        })
    ),
};

/*
NavComponent.defaultProps = {
    extend: false,
    navList: null
};
*/

export default NavComponent;