const initialState = {
    locale: 'en',
    messages: {} // Load your messages here or dynamically
};

function intlReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_LOCALE':
            return {
                ...state,
                locale: action.payload.locale,
                messages: action.payload.messages
            };
        default:
            return state;
    }
}

export default intlReducer;
