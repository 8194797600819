import _ from 'lodash';
import { getArticleList, getArticleContentList } from '../sources/articles';
import { FETCH_ARTICLES, FETCH_ARTICLE_CONTENT } from '../types/articles';
import store from '../utils/configureStore';

const { intl: { locale } } = store.getState();

export function fetchArticles() {
	return {
		type: FETCH_ARTICLES,
		payload: getArticleList(locale)
	};
}

export function fetchArticleContent(articleId) {
	const articleContent = fetchArticleById(getArticleContentList(locale), articleId);

	return {
		type: FETCH_ARTICLE_CONTENT,
		payload: articleContent
	};
}

/** */

function fetchArticleById(list, articleId) {
	return _.find(list, article => article.id === articleId);
}
